.container
  display block
  margin 0 auto
  max-width 1172px
  min-width 320px
  padding 0 10px

  +below('l')
    padding 0 32px

  +below('m')
    padding 0 16px

  &--admin
    width 1172px
    display block
    margin 0 auto
    padding 0 10px

  &--operator
    padding 0
    max-width 1920px
    height 100%

  &-small
    width auto
    max-width 960px
    min-width 320px
    margin 32px auto 64px
    background-color #fff

    +below('l')
      padding 0


  &--account
    width auto
    padding 0 0 0 2%
    margin-left 240px
    width-right auto
    max-width 864px
    min-width 320px

    +below('xl')
      padding 0 64px 0 32px

    +below('l')
      margin 0 auto
      padding 0 32px

    +below('m')
      padding 0 16px

  //баланс
  &--balance
    width auto
    padding 0 0 0 2%
    margin-left 240px
    width-right auto
    max-width 864px
    min-width 320px

    +below('xl')
      padding 0 64px 0 32px

    +below('l')
      margin 0 auto
      padding 0 32px

    +below('m')
      padding 0

  //звонки
  &--call
    width auto
    padding 0 0 0 32px
    max-width 896px
    min-width 320px
    margin-left 240px

    +below('xl')
      max-width 864px
      min-width 320px
      padding 0 64px 0 32px

    +below('l')
      padding 0
      margin 0

    +below('m')
      padding 0 16px 0 0

  &--settings
    width auto
    padding 0 0 0 32px
    max-width 896px
    min-width 320px
    margin-left 240px

    +below('xl')
      max-width 864px
      min-width 320px
      padding 0 64px 0 32px

    +below('l')
      padding 0
      margin 0

    +below('m')
      padding 0 16px 0 0

  //страница онбординг,
  &--onboarding
    width 100%
    max-width 960px
    min-width 320px
    margin 32px auto 0
    background-color #fff

    +below('l')
      flex 1 0 auto
      margin 0

    +below('m')
      display block
      flex none
      margin 32px 0 0 0

  &--authorization
    width 100%
    max-width 576px
    min-width 320px
    margin 64px auto

    +below('m')
      margin 20px auto 0

  //контейнер для алертов
  &--top
    padding-top 16px

    +below('l')
      padding 0
      margin 0

    & + .container--main
      margin-top 0
      padding-top 0

    & + .container--normal
      margin-top 0
      padding-top 0

  &--main
    width auto
    padding 32px 0 0 32px
    max-width 896px
    min-width 320px
    margin-left 240px

    +below('xl')
      max-width 864px
      min-width 320px
      padding 32px 64px 0 32px

    +below('l')
      padding 0
      margin 0

    +below('m')
      padding 0 16px 0 0

  &--normal
    width auto
    padding 32px 0 0 32px
    margin-bottom 64px
    max-width 896px
    min-width 320px
    margin-left 240px

    +below('xl')
      max-width 864px
      min-width 320px
      padding 32px 64px 0 32px

    +below('l')
      padding 0
      margin 0

  &--panel
    width auto
    padding 0 0 0 32px
    margin-bottom 64px
    max-width 896px
    min-width 320px
    margin-left 240px

    +below('xl')
      max-width 864px
      min-width 320px
      padding 0 64px 0 32px

    +below('l')
      padding 0
      margin 0


