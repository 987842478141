.checkout
  padding 64px 116px 48px
  background-color #fff

  +below('l')
    padding 64px 58px 48px

  +below('m')
    padding 0 5%

  &__top
    margin 0 0 24px 0

  &__title
    h2()
    margin 0 0 8px 0

  &__text
    margin 0 0 8px 0

