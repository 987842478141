.radio
  inline-block(top)
  p()
  position relative
  margin 0
  min-height 32px
  color $black
  cursor pointer

  &--sub

    &:hover .radio__icon
      background-color transparent!important

    .radio__icon
      size(20px)
      position absolute
      vertical-align middle
      background-color transparent

    .radio__icon:after
    .radio__icon:before
      top 0
      left 0

    .radio__input:checked + .radio__icon
      background-color #eee

    .radio__indicator:before
    .radio__indicator:after
      content ''
      left 0
      top 0

    .radio__text
      display block
      padding 0 0 0 35px
      margin 1px 0 0 0


  &:hover
    .radio__icon:before
      border-color $silverChalice
      border-radius 50%

    .radio__icon
      background-color #ccc

  &__input
    position absolute
    display none

  &__icon
    position absolute
    inline-block(middle)
    size(32px)
    vertical-align middle
    background-color #eee
    top 0
    left 0
    text-align left
    border 1px solid transparent
    border-radius 50%
    transition all .3s cubic-bezier(.4,1.34,.51,1.01)


    &:after
    &:before
      content ''
      position absolute
      left 5px
      top 5px
      background-position center center
      background-repeat no-repeat
      transition all .3s cubic-bezier(.4,1.34,.51,1.01)

    &:after
      size(12px)
      background-color $blackBunting
      border-radius 50%
      opacity 0
      transform scale(0)
      margin 4px

    &:before
      size(18px)
      border 1px solid #ccc
      border-radius 50%
      background-color white

  &__input:checked + .radio__icon

    &:before
      border 1px solid $blackPearl
      border-radius 50%

    &:after
      opacity 1
      transform scale(1)

  &__text
    inline-block(middle)
    margin 0 0 0 45px
    padding 6px 0 0 0

  &__text-muted
    margin 0 0 0 6px

    +below('m')
      margin 0
      display block
