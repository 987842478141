.phone-number
  display block
  margin 0 0 8px 0
  font-size 16px
  line-height (22/16)
  font-family $Muller
  font-weight 400
  color #000
  pointer-events none
  
  &:first-child
    margin 5px 0 8px 0

  &:last-child
    margin 0

  +below('l')
    pointer-events initial
    color $blueRibbon

  &--hide
    display none

  &__code
  &__seconds
  &__seconds-value
  &__hash
    color #000
    
    +below('l')
      color $blueRibbon
