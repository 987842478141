.checkbox-list
  display block
  padding 0 0 0 44px

  &.show
    display block


  & &__counter
    display none
    padding 0 0 0 35px
    margin 8px 0 0 0
