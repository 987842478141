table
.table
  text-align left
  margin 20px 0
  border-spacing 0
  border-collapse collapse

  &--full
    width 100%
    min-width 100%
    
  thead
    padding 10px 10px 10px 0
    background-color #FAFAFA

    th
      padding 16px 11px
      font-family $Muller
      font-size 16px
      line-height 22px
      font-weight 400
      color #9F9D9D

      &:first-child
        padding 16px 11px 16px 31px

      &:last-child
        padding 16px 11px 16px 11px

  tbody

    tr
      line-height 18px
      border-bottom 1px solid #D8D8D8

    th
      padding 16px 11px
      font-family $Muller
      font-size 16px
      line-height 22px
      font-weight 400
      color #3E3E46

      &:first-child
        padding 16px 11px 16px 31px

      &:last-child
        padding 16px 11px 16px 11px

      &.blue
        color #326EC8

      &.disabled
        opacity .4

      .status-blue
        inline-block(top)
        line-height 28px
        height 28px
        padding 0 11px 0 8px
        background #326EC8
        color #fff
        border-radius 3px

      .status-yellow
        inline-block(top)
        line-height 28px
        height 28px
        padding 0 11px 0 8px
        background #FFDC8C
        color #3E3E46
        border-radius 3px

  &__status
    position relative
    padding 0 0 0 18px

    &:before
      content ''
      size(10px)
      position absolute
      top 50%
      left 0
      transform translate(0,-50%)
      display block
      border-radius 50%

  &__status--nok
    &:before
      background $redRibbon

  &__status--ok
    &:before
      background $jade
