.btn
  inline-block(top)
  border-width 1px
  border-style solid

  text-align center
  text-decoration none

  font-family $Muller
  font-weight 400

  white-space nowrap

  transition all $time
  cursor pointer

  user-select none
  outline none

  color #000

  &.disabled
  &.btn--disabled
  &[disabled]
    cursor default
    pointer-events none
    opacity .2

  svg
    inline-block(top)
    margin 6px 0 0 0
    margin-right 6px


  & &__icon
    margin 0 8.5px 0 0
    vertical-align middle
    fill currentColor

  /*==============================
  =            SIZES             =
  ==============================*/

  &--standard
    padding 0 20px
    height 40px
    font-size 16px
    line-height 40px

  &--big
    padding 0 32px
    height 48px
    font-size 16px
    line-height 48px

  &--small
    padding 0 17px
    height 32px
    line-height 32px
    font-size 14px

  /*-----  End of SIZES  ------*/

  /*==============================
  =            STYLES            =
  ==============================*/

  &--negative
    border-radius 100px
    background #E30040
    border-color #E30040
    color #fff

  &:hover
    background #A80634
    border-color #A80634
    color #fff

  &:active
  &:focus
    background #050D25
    border-color #050D25
    color #fff

  &--negative
    border-radius 100px
    background #E30040
    border-color #E30040
    color #fff

    &:hover
      background #A80634
      border-color #A80634
      color #fff

    &:active
    &:focus
      background #050D25
      border-color #050D25
      color #fff

    //&.disabled
    //&.btn--disabled
    //&[disabled]
    //  background #999 !important
    //  border-color #999 !important
    //  color #c2c2c2 !important

  &--primary
    background #0055FF
    border-color #0055FF
    color #fff
    border-radius 100px

    &:hover
      background #003CB4
      border-color #003CB4
      color #fff

    &:active
    &:focus
      background #050D25
      border-color #050D25
      color #fff

  &--primary-inversion
    background #FFF
    border-color #FFF
    color #0055FF
    border-radius 100px

    &:active
    &:focus
    &:hover
      background transparent
      border-color #FFF
      color #FFF

    //&:active
    //&:focus
    //  background #050D25
    //  border-color #050D25
    //  color #fff

  &--secondary
    border-radius 100px
    background transparent
    border-color #0055FF
    color #0055FF

    &:hover
      background #003CB4
      border-color #003CB4
      color #fff

    &:active
    &:focus
      background #050D25
      border-color #050D25
      color #fff

  &--secondary-inversion
    border-radius 100px
    background transparent
    border-color #FFF
    color #FFF

    &:active
    &:focus
    &:hover
      background #FFF
      border-color #FFF
      color #0055FF

  &--transparent
    background-color transparent
    border none

    &:hover
    &:active
      background-color initial
      border none
      color initial

  &--reset
    color $blueRibbon!important
    background-color transparent!important

    &:active
    &:hover
      color $blueRibbon!important
      background-color transparent!important

  &--bubble
    padding 6px
    line-height 0
    background transparent
    border-radius 50%
    border 1px solid currentColor
    box-sizing content-box

    svg
      margin 0
      fill currentColor

  &--tertiary
    color $jade

    svg
      fill $bluishGreen

    &:hover
      border-color $jade
      background $jade

      svg
        fill $white

    &:active
    &:focus
      background $blackPearl
      border-color $blackPearl

      svg
        fill $white

  /*-----  End of STYLES  ------*/

