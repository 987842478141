.radio-content
  display block
  max-width 512px
  min-width 288px
  padding 20px 44px 24px
  border 1px dotted $blueRibbon
  border-radius 5px
  margin 18px 0 0 0
  font-size 0

  +below('m')
    padding 20px 32px 28px 32px

  +below('small-mobile')
    width 288px

  &--hide
    display none

  &__text-muted
    font-size 14px
    line-height (20/14)
    font-family $Muller
    font-weight 400
    margin 0 0 12px 0
    color $c_muted

  &__btn-wrap
    text-align right

  &__item
    inline-block(middle)
    width 50%

    +below('l')
      width 100%

    & + &

      +below('l')
        display none

  &__icon-call
    size(24px)
    fill $blueRibbon
    vertical-align middle
    margin-right 5px
