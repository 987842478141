.table-content
  @extend .cf
  display block
  margin 18px 0 0 0
  width 100%
  position relative
  background #fff
  font-size 0

  td
  th
    font-family $Muller
    font-weight 400
    font-size 16px
    line-height (20/16)
    border 1px solid #eee
    border-bottom none
    border-left none
    border-right none
    color #000
    text-align left
    
    +below('m')
      font-size 14px
  
  td:last-child
  th:last-child
    border-bottom 1px solid #eee
  
  th
    font-weight normal
    text-align right
    padding 15px 10px 15px 0
    
    +below('m')
      padding 12px 10px 12px 0
  
  td
    padding 15px 15px

    +below('m')
      padding 12px 15px
    
  thead
    display block
    float left
    padding 0
    background-color transparent
    
  thead th:first-child
    padding 15px 10px 15px 0

    +below('m')
      padding 12px 10px 12px 0
    
  thead th:last-child
    padding 15px 10px 15px 0

    +below('m')
      padding 12px 10px 12px 0
    
  thead tr th:first-child
    background-color #F6F6F6
    
  tbody tr td:first-child
    background-color #F6F6F6  
  
  thead tr
    display block
  
  thead th
    display block
    font-weight normal
    text-align left
  
  tbody
    display block
    width auto
    white-space nowrap
    position relative
    
    +below('m')
      overflow-y hidden
  
  tbody tr
    display inline-block
    vertical-align top
    border-bottom none
  
  tbody td
    display block
    
  &__tbody:before
    content ''
    
    +below('m')
      display block
      width 65px
      height 100%
      right -5px
      position absolute
      background-image linear-gradient(-90deg,#fff,hsla(0,100%,0,0));
