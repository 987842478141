/*------------------------------------*
    $CSSWIZARDRY-GRIDS
\*------------------------------------*/
/**
 * CONTENTS
 * INTRODUCTION.........How the grid system works.
 * VARIABLES............Your settings.
 * MIXINS...............Library mixins.
 * GRID SETUP...........Build the grid structure.
 * WIDTHS...............Build our responsive widths around our breakpoints.
 * PUSH.................Push classes.
 * PULL.................Pull classes.
 */
/*------------------------------------*
    $INTRODUCTION
\*------------------------------------*/
/**
 * csswizardry grids provides you with widths to suit a number of breakpoints
 * designed around devices of a size you specify. Out of the box, csswizardry
 * grids caters to the following types of device:
 *
 * palm     --  palm-based devices, like phones and small tablets
 * lap      --  lap-based devices, like iPads or laptops
 * portable --  all of the above
 * desk     --  stationary devices, like desktop computers
 * regular  --  any/all types of device
 *
 * These namespaces are then used in the library to give you the ability to
 * manipulate your layouts based around them, for example:
 *
   <div class="grid__item  one-whole  lap--one-half  desk--one-third">
 *
 * This would give you a grid item which is 100% width unless it is on a lap
 * device, at which point it become 50% wide, or it is on a desktop device, at
 * which point it becomes 33.333% width.
 *
 * csswizardry grids also has push and pull classes which allow you to nudge
 * grid items left and right by a defined amount. These follow the same naming
 * convention as above, but are prepended by either `push--` or `pull--`, for
 * example:
 *
   `class="grid__item  one-half  push--one-half"`
 *
 * This would give you a grid item which is 50% width and pushed over to the
 * right by 50%.
 *
 * All classes in csswizardry grids follow this patten, so you should fairly
 * quickly be able to piece together any combinations you can imagine, for
 * example:
 *
   `class="grid__item  one-whole  lap--one-half  desk--one-third  push--desk--one-third"`
 *
   `class="grid__item  one-quarter  palm--one-half  push--palm--one-half"`
 *
   `class="grid__item  palm--one-third  desk--five-twelfths"`
 */
/*------------------------------------*
    $VARIABLES
\*------------------------------------*/
/**
 * If you are building a non-responsive site but would still like to use
 * csswizardry-grids, set this to false:
 */
/**
 * Is this build mobile first? Setting to true means that all grids will be 100%
 * width if you do not apply a more specific class to them.
 */
/**
 * Set the spacing between your grid items.
 */
/**
 * Would you like Sass’ silent classes, or regular CSS classes?
 */
/**
 * Would you like push and pull classes enabled?
 */
/**
 * Use floats instead of display: inline-block?
 */
/**
 * Using `inline-block` means that the grid items need their whitespace removing
 * in order for them to work correctly. Set the following to true if you are
 * going to achieve this by manually removing/commenting out any whitespace in
 * your HTML yourself.
 *
 * Setting this to false invokes a hack which cannot always be guaranteed,
 * please see the following for more detail:
 *
 * github.com/csswizardry/csswizardry-grids/commit/744d4b23c9d2b77d605b5991e54a397df72e0688
 * github.com/csswizardry/inuit.css/issues/170#issuecomment-14859371
 */
/**
 * Define your breakpoints. The first value is the prefix that shall be used for
 * your classes (e.g. `.palm--one-half`), the second value is the media query
 * that the breakpoint fires at.
 */
/**
 * Define which namespaced breakpoints you would like to generate for each of
 * widths, push and pull. This is handy if you only need pull on, say, desk, or
 * you only need a new width breakpoint at mobile sizes. It allows you to only
 * compile as much CSS as you need. All are turned on by default, but you can
 * add and remove breakpoints at will.
 *
 * Push and pull shall only be used if `$push` and/or `$pull` and `$responsive`
 * have been set to ‘true’.
 */
/**
 * If you don't like the default class names, you can customize them here.
 */
/**
 * You do not need to edit anything from this line onward; csswizardry-grids is
 * good to go. Happy griddin’!
 */
/*------------------------------------*
    $MIXINS
\*------------------------------------*/
/**
 * These mixins are for the library to use only, you should not need to modify
 * them at all.
 *
 * Enclose a block of code with a media query as named in `$breakpoints`.
 */
/**
 * Drop relative positioning into silent classes, which can’t take advantage of
 * the `[class*="push--"]` and `[class*="pull--"]` selectors.
 */
/**
 * Mixin to create the different sizes dynamically
 */
/*------------------------------------*
    $GRID SETUP
\*------------------------------------*/
/**
 * 1. Allow the grid system to be used on lists.
 * 2. Remove any margins and paddings that might affect the grid system.
 * 3. Apply a negative `margin-left` to negate the columns’ gutters.
 */
.grid {
	list-style: none /* [1] */;
	margin: 0 /* [2] */;
	padding: 0 /* [2] */;
	margin-left: 0 /* [3] */;
	font-size: 0;
}
/**
 * 1. Cause columns to stack side-by-side.
 * 2. Space columns apart.
 * 3. Align columns to the tops of each other.
 * 4. Full-width unless told to behave otherwise.
 * 5. Required to combine fluid widths and fixed gutters.
 */
.grid__item {
	display: inline-block /* [1] */;
	padding-left: 0 /* [2] */;
	vertical-align: top /* [3] */;
	width: 100% /* [4] */;
	box-sizing: border-box /* [5] */;
	position: relative;
	font-size: initial;
}
.grid--rev {
	direction: rtl;
	text-align: left;
}
.grid--rev > .grid__item {
	direction: ltr;
	text-align: left;
}
.grid--full {
	margin-left: 0;
}
.grid--full > .grid__item {
	padding-left: 0;
}
.grid--right {
	text-align: right;
}
.grid--right > .grid__item {
	text-align: left;
}
.grid--center {
	text-align: center;
}
.grid--center > .grid__item {
	text-align: left;
}
.grid--middle > .grid__item {
	vertical-align: middle;
}
.grid--bottom > .grid__item {
	vertical-align: bottom;
}
.grid--narrow {
	margin-left: 0;
}
.grid--narrow > .grid__item {
	padding-left: 0;
}
.grid--wide {
	margin-left: 0;
}
.grid--wide > .grid__item {
	padding-left: 0;
}
/*------------------------------------*
    $WIDTHS
\*------------------------------------*/
.one-whole {
	width: 100%;
}
.one-half {
	width: 50%;
}
.push--one-half {
	left: 50%;
}
.one-third {
	width: 33.33333333333333%;
}
.push--one-third {
	left: 33.33333333333333%;
}
.two-thirds {
	width: 66.66666666666666%;
}
.push--two-thirds {
	left: 66.66666666666666%;
}
.one-quarter {
	width: 25%;
}
.push--one-quarter {
	left: 25%;
}
.two-quarters {
	width: 50%;
}
.push--two-quarters {
	left: 50%;
}
.three-quarters {
	width: 75%;
}
.push--three-quarters {
	left: 75%;
}
.one-fifth {
	width: 20%;
}
.push--one-fifth {
	left: 20%;
}
.two-fifths {
	width: 40%;
}
.push--two-fifths {
	left: 40%;
}
.three-fifths {
	width: 60%;
}
.push--three-fifths {
	left: 60%;
}
.four-fifths {
	width: 80%;
}
.push--four-fifths {
	left: 80%;
}
.one-sixth {
	width: 16.666666666666664%;
}
.push--one-sixth {
	left: 16.666666666666664%;
}
.two-sixths {
	width: 33.33333333333333%;
}
.push--two-sixths {
	left: 33.33333333333333%;
}
.three-sixths {
	width: 50%;
}
.push--three-sixths {
	left: 50%;
}
.four-sixths {
	width: 66.66666666666666%;
}
.push--four-sixths {
	left: 66.66666666666666%;
}
.five-sixths {
	width: 83.33333333333334%;
}
.push--five-sixths {
	left: 83.33333333333334%;
}
.one-seventh {
	width: 14.285714285714285%;
}
.push--one-seventh {
	left: 14.285714285714285%;
}
.two-sevenths {
	width: 28.57142857142857%;
}
.push--two-sevenths {
	left: 28.57142857142857%;
}
.three-sevenths {
	width: 42.857142857142854%;
}
.push--three-sevenths {
	left: 42.857142857142854%;
}
.four-sevenths {
	width: 57.14285714285714%;
}
.push--four-sevenths {
	left: 57.14285714285714%;
}
.five-sevenths {
	width: 71.42857142857143%;
}
.push--five-sevenths {
	left: 71.42857142857143%;
}
.six-sevenths {
	width: 85.71428571428571%;
}
.push--six-sevenths {
	left: 85.71428571428571%;
}
.one-eighth {
	width: 12.5%;
}
.push--one-eighth {
	left: 12.5%;
}
.two-eighths {
	width: 25%;
}
.push--two-eighths {
	left: 25%;
}
.three-eighths {
	width: 37.5%;
}
.push--three-eighths {
	left: 37.5%;
}
.four-eighths {
	width: 50%;
}
.push--four-eighths {
	left: 50%;
}
.five-eighths {
	width: 62.5%;
}
.push--five-eighths {
	left: 62.5%;
}
.six-eighths {
	width: 75%;
}
.push--six-eighths {
	left: 75%;
}
.seven-eighths {
	width: 87.5%;
}
.push--seven-eighths {
	left: 87.5%;
}
.one-nineth {
	width: 11.11111111111111%;
}
.push--one-nineth {
	left: 11.11111111111111%;
}
.two-nineths {
	width: 22.22222222222222%;
}
.push--two-nineths {
	left: 22.22222222222222%;
}
.three-nineths {
	width: 33.33333333333333%;
}
.push--three-nineths {
	left: 33.33333333333333%;
}
.four-nineths {
	width: 44.44444444444444%;
}
.push--four-nineths {
	left: 44.44444444444444%;
}
.five-nineths {
	width: 55.55555555555556%;
}
.push--five-nineths {
	left: 55.55555555555556%;
}
.six-nineths {
	width: 66.66666666666666%;
}
.push--six-nineths {
	left: 66.66666666666666%;
}
.seven-nineths {
	width: 77.77777777777779%;
}
.push--seven-nineths {
	left: 77.77777777777779%;
}
.eight-nineths {
	width: 88.88888888888889%;
}
.push--eight-nineths {
	left: 88.88888888888889%;
}
.one-tenth {
	width: 10%;
}
.push--one-tenth {
	left: 10%;
}
.two-tenths {
	width: 20%;
}
.push--two-tenths {
	left: 20%;
}
.three-tenths {
	width: 30%;
}
.push--three-tenths {
	left: 30%;
}
.four-tenths {
	width: 40%;
}
.push--four-tenths {
	left: 40%;
}
.five-tenths {
	width: 50%;
}
.push--five-tenths {
	left: 50%;
}
.six-tenths {
	width: 60%;
}
.push--six-tenths {
	left: 60%;
}
.seven-tenths {
	width: 70%;
}
.push--seven-tenths {
	left: 70%;
}
.eight-tenths {
	width: 80%;
}
.push--eight-tenths {
	left: 80%;
}
.nine-tenths {
	width: 90%;
}
.push--nine-tenths {
	left: 90%;
}
.one-twelfth {
	width: 8.333333333333332%;
}
.push--one-twelfth {
	left: 8.333333333333332%;
}
.two-twelfths {
	width: 16.666666666666664%;
}
.push--two-twelfths {
	left: 16.666666666666664%;
}
.three-twelfths {
	width: 25%;
}
.push--three-twelfths {
	left: 25%;
}
.four-twelfths {
	width: 33.33333333333333%;
}
.push--four-twelfths {
	left: 33.33333333333333%;
}
.five-twelfths {
	width: 41.66666666666667%;
}
.push--five-twelfths {
	left: 41.66666666666667%;
}
.six-twelfths {
	width: 50%;
}
.push--six-twelfths {
	left: 50%;
}
.seven-twelfths {
	width: 58.333333333333336%;
}
.push--seven-twelfths {
	left: 58.333333333333336%;
}
.eight-twelfths {
	width: 66.66666666666666%;
}
.push--eight-twelfths {
	left: 66.66666666666666%;
}
.nine-twelfths {
	width: 75%;
}
.push--nine-twelfths {
	left: 75%;
}
.ten-twelfths {
	width: 83.33333333333334%;
}
.push--ten-twelfths {
	left: 83.33333333333334%;
}
.eleven-twelfths {
	width: 91.66666666666666%;
}
.push--eleven-twelfths {
	left: 91.66666666666666%;
}
@media (max-width: 420px) {
	.palm--one-whole {
		width: 100%;
	}
	.palm--one-half {
		width: 50%;
	}
	.push--palm--one-half {
		left: 50%;
	}
	.palm--one-third {
		width: 33.33333333333333%;
	}
	.push--palm--one-third {
		left: 33.33333333333333%;
	}
	.palm--two-thirds {
		width: 66.66666666666666%;
	}
	.push--palm--two-thirds {
		left: 66.66666666666666%;
	}
	.palm--one-quarter {
		width: 25%;
	}
	.push--palm--one-quarter {
		left: 25%;
	}
	.palm--two-quarters {
		width: 50%;
	}
	.push--palm--two-quarters {
		left: 50%;
	}
	.palm--three-quarters {
		width: 75%;
	}
	.push--palm--three-quarters {
		left: 75%;
	}
	.palm--one-fifth {
		width: 20%;
	}
	.push--palm--one-fifth {
		left: 20%;
	}
	.palm--two-fifths {
		width: 40%;
	}
	.push--palm--two-fifths {
		left: 40%;
	}
	.palm--three-fifths {
		width: 60%;
	}
	.push--palm--three-fifths {
		left: 60%;
	}
	.palm--four-fifths {
		width: 80%;
	}
	.push--palm--four-fifths {
		left: 80%;
	}
	.palm--one-sixth {
		width: 16.666666666666664%;
	}
	.push--palm--one-sixth {
		left: 16.666666666666664%;
	}
	.palm--two-sixths {
		width: 33.33333333333333%;
	}
	.push--palm--two-sixths {
		left: 33.33333333333333%;
	}
	.palm--three-sixths {
		width: 50%;
	}
	.push--palm--three-sixths {
		left: 50%;
	}
	.palm--four-sixths {
		width: 66.66666666666666%;
	}
	.push--palm--four-sixths {
		left: 66.66666666666666%;
	}
	.palm--five-sixths {
		width: 83.33333333333334%;
	}
	.push--palm--five-sixths {
		left: 83.33333333333334%;
	}
	.palm--one-seventh {
		width: 14.285714285714285%;
	}
	.push--palm--one-seventh {
		left: 14.285714285714285%;
	}
	.palm--two-sevenths {
		width: 28.57142857142857%;
	}
	.push--palm--two-sevenths {
		left: 28.57142857142857%;
	}
	.palm--three-sevenths {
		width: 42.857142857142854%;
	}
	.push--palm--three-sevenths {
		left: 42.857142857142854%;
	}
	.palm--four-sevenths {
		width: 57.14285714285714%;
	}
	.push--palm--four-sevenths {
		left: 57.14285714285714%;
	}
	.palm--five-sevenths {
		width: 71.42857142857143%;
	}
	.push--palm--five-sevenths {
		left: 71.42857142857143%;
	}
	.palm--six-sevenths {
		width: 85.71428571428571%;
	}
	.push--palm--six-sevenths {
		left: 85.71428571428571%;
	}
	.palm--one-eighth {
		width: 12.5%;
	}
	.push--palm--one-eighth {
		left: 12.5%;
	}
	.palm--two-eighths {
		width: 25%;
	}
	.push--palm--two-eighths {
		left: 25%;
	}
	.palm--three-eighths {
		width: 37.5%;
	}
	.push--palm--three-eighths {
		left: 37.5%;
	}
	.palm--four-eighths {
		width: 50%;
	}
	.push--palm--four-eighths {
		left: 50%;
	}
	.palm--five-eighths {
		width: 62.5%;
	}
	.push--palm--five-eighths {
		left: 62.5%;
	}
	.palm--six-eighths {
		width: 75%;
	}
	.push--palm--six-eighths {
		left: 75%;
	}
	.palm--seven-eighths {
		width: 87.5%;
	}
	.push--palm--seven-eighths {
		left: 87.5%;
	}
	.palm--one-nineth {
		width: 11.11111111111111%;
	}
	.push--palm--one-nineth {
		left: 11.11111111111111%;
	}
	.palm--two-nineths {
		width: 22.22222222222222%;
	}
	.push--palm--two-nineths {
		left: 22.22222222222222%;
	}
	.palm--three-nineths {
		width: 33.33333333333333%;
	}
	.push--palm--three-nineths {
		left: 33.33333333333333%;
	}
	.palm--four-nineths {
		width: 44.44444444444444%;
	}
	.push--palm--four-nineths {
		left: 44.44444444444444%;
	}
	.palm--five-nineths {
		width: 55.55555555555556%;
	}
	.push--palm--five-nineths {
		left: 55.55555555555556%;
	}
	.palm--six-nineths {
		width: 66.66666666666666%;
	}
	.push--palm--six-nineths {
		left: 66.66666666666666%;
	}
	.palm--seven-nineths {
		width: 77.77777777777779%;
	}
	.push--palm--seven-nineths {
		left: 77.77777777777779%;
	}
	.palm--eight-nineths {
		width: 88.88888888888889%;
	}
	.push--palm--eight-nineths {
		left: 88.88888888888889%;
	}
	.palm--one-tenth {
		width: 10%;
	}
	.push--palm--one-tenth {
		left: 10%;
	}
	.palm--two-tenths {
		width: 20%;
	}
	.push--palm--two-tenths {
		left: 20%;
	}
	.palm--three-tenths {
		width: 30%;
	}
	.push--palm--three-tenths {
		left: 30%;
	}
	.palm--four-tenths {
		width: 40%;
	}
	.push--palm--four-tenths {
		left: 40%;
	}
	.palm--five-tenths {
		width: 50%;
	}
	.push--palm--five-tenths {
		left: 50%;
	}
	.palm--six-tenths {
		width: 60%;
	}
	.push--palm--six-tenths {
		left: 60%;
	}
	.palm--seven-tenths {
		width: 70%;
	}
	.push--palm--seven-tenths {
		left: 70%;
	}
	.palm--eight-tenths {
		width: 80%;
	}
	.push--palm--eight-tenths {
		left: 80%;
	}
	.palm--nine-tenths {
		width: 90%;
	}
	.push--palm--nine-tenths {
		left: 90%;
	}
	.palm--one-twelfth {
		width: 8.333333333333332%;
	}
	.push--palm--one-twelfth {
		left: 8.333333333333332%;
	}
	.palm--two-twelfths {
		width: 16.666666666666664%;
	}
	.push--palm--two-twelfths {
		left: 16.666666666666664%;
	}
	.palm--three-twelfths {
		width: 25%;
	}
	.push--palm--three-twelfths {
		left: 25%;
	}
	.palm--four-twelfths {
		width: 33.33333333333333%;
	}
	.push--palm--four-twelfths {
		left: 33.33333333333333%;
	}
	.palm--five-twelfths {
		width: 41.66666666666667%;
	}
	.push--palm--five-twelfths {
		left: 41.66666666666667%;
	}
	.palm--six-twelfths {
		width: 50%;
	}
	.push--palm--six-twelfths {
		left: 50%;
	}
	.palm--seven-twelfths {
		width: 58.333333333333336%;
	}
	.push--palm--seven-twelfths {
		left: 58.333333333333336%;
	}
	.palm--eight-twelfths {
		width: 66.66666666666666%;
	}
	.push--palm--eight-twelfths {
		left: 66.66666666666666%;
	}
	.palm--nine-twelfths {
		width: 75%;
	}
	.push--palm--nine-twelfths {
		left: 75%;
	}
	.palm--ten-twelfths {
		width: 83.33333333333334%;
	}
	.push--palm--ten-twelfths {
		left: 83.33333333333334%;
	}
	.palm--eleven-twelfths {
		width: 91.66666666666666%;
	}
	.push--palm--eleven-twelfths {
		left: 91.66666666666666%;
	}
}
.palm--shown {
	display: none !important;
}
@media (max-width: 420px) {
	.palm--shown {
		display: block !important;
	}
}
@media (max-width: 420px) {
	.palm--hidden {
		display: none !important;
	}
}
@media (max-width: 640px) {
	.lap--one-whole {
		width: 100%;
	}
	.lap--one-half {
		width: 50%;
	}
	.push--lap--one-half {
		left: 50%;
	}
	.lap--one-third {
		width: 33.33333333333333%;
	}
	.push--lap--one-third {
		left: 33.33333333333333%;
	}
	.lap--two-thirds {
		width: 66.66666666666666%;
	}
	.push--lap--two-thirds {
		left: 66.66666666666666%;
	}
	.lap--one-quarter {
		width: 25%;
	}
	.push--lap--one-quarter {
		left: 25%;
	}
	.lap--two-quarters {
		width: 50%;
	}
	.push--lap--two-quarters {
		left: 50%;
	}
	.lap--three-quarters {
		width: 75%;
	}
	.push--lap--three-quarters {
		left: 75%;
	}
	.lap--one-fifth {
		width: 20%;
	}
	.push--lap--one-fifth {
		left: 20%;
	}
	.lap--two-fifths {
		width: 40%;
	}
	.push--lap--two-fifths {
		left: 40%;
	}
	.lap--three-fifths {
		width: 60%;
	}
	.push--lap--three-fifths {
		left: 60%;
	}
	.lap--four-fifths {
		width: 80%;
	}
	.push--lap--four-fifths {
		left: 80%;
	}
	.lap--one-sixth {
		width: 16.666666666666664%;
	}
	.push--lap--one-sixth {
		left: 16.666666666666664%;
	}
	.lap--two-sixths {
		width: 33.33333333333333%;
	}
	.push--lap--two-sixths {
		left: 33.33333333333333%;
	}
	.lap--three-sixths {
		width: 50%;
	}
	.push--lap--three-sixths {
		left: 50%;
	}
	.lap--four-sixths {
		width: 66.66666666666666%;
	}
	.push--lap--four-sixths {
		left: 66.66666666666666%;
	}
	.lap--five-sixths {
		width: 83.33333333333334%;
	}
	.push--lap--five-sixths {
		left: 83.33333333333334%;
	}
	.lap--one-seventh {
		width: 14.285714285714285%;
	}
	.push--lap--one-seventh {
		left: 14.285714285714285%;
	}
	.lap--two-sevenths {
		width: 28.57142857142857%;
	}
	.push--lap--two-sevenths {
		left: 28.57142857142857%;
	}
	.lap--three-sevenths {
		width: 42.857142857142854%;
	}
	.push--lap--three-sevenths {
		left: 42.857142857142854%;
	}
	.lap--four-sevenths {
		width: 57.14285714285714%;
	}
	.push--lap--four-sevenths {
		left: 57.14285714285714%;
	}
	.lap--five-sevenths {
		width: 71.42857142857143%;
	}
	.push--lap--five-sevenths {
		left: 71.42857142857143%;
	}
	.lap--six-sevenths {
		width: 85.71428571428571%;
	}
	.push--lap--six-sevenths {
		left: 85.71428571428571%;
	}
	.lap--one-eighth {
		width: 12.5%;
	}
	.push--lap--one-eighth {
		left: 12.5%;
	}
	.lap--two-eighths {
		width: 25%;
	}
	.push--lap--two-eighths {
		left: 25%;
	}
	.lap--three-eighths {
		width: 37.5%;
	}
	.push--lap--three-eighths {
		left: 37.5%;
	}
	.lap--four-eighths {
		width: 50%;
	}
	.push--lap--four-eighths {
		left: 50%;
	}
	.lap--five-eighths {
		width: 62.5%;
	}
	.push--lap--five-eighths {
		left: 62.5%;
	}
	.lap--six-eighths {
		width: 75%;
	}
	.push--lap--six-eighths {
		left: 75%;
	}
	.lap--seven-eighths {
		width: 87.5%;
	}
	.push--lap--seven-eighths {
		left: 87.5%;
	}
	.lap--one-nineth {
		width: 11.11111111111111%;
	}
	.push--lap--one-nineth {
		left: 11.11111111111111%;
	}
	.lap--two-nineths {
		width: 22.22222222222222%;
	}
	.push--lap--two-nineths {
		left: 22.22222222222222%;
	}
	.lap--three-nineths {
		width: 33.33333333333333%;
	}
	.push--lap--three-nineths {
		left: 33.33333333333333%;
	}
	.lap--four-nineths {
		width: 44.44444444444444%;
	}
	.push--lap--four-nineths {
		left: 44.44444444444444%;
	}
	.lap--five-nineths {
		width: 55.55555555555556%;
	}
	.push--lap--five-nineths {
		left: 55.55555555555556%;
	}
	.lap--six-nineths {
		width: 66.66666666666666%;
	}
	.push--lap--six-nineths {
		left: 66.66666666666666%;
	}
	.lap--seven-nineths {
		width: 77.77777777777779%;
	}
	.push--lap--seven-nineths {
		left: 77.77777777777779%;
	}
	.lap--eight-nineths {
		width: 88.88888888888889%;
	}
	.push--lap--eight-nineths {
		left: 88.88888888888889%;
	}
	.lap--one-tenth {
		width: 10%;
	}
	.push--lap--one-tenth {
		left: 10%;
	}
	.lap--two-tenths {
		width: 20%;
	}
	.push--lap--two-tenths {
		left: 20%;
	}
	.lap--three-tenths {
		width: 30%;
	}
	.push--lap--three-tenths {
		left: 30%;
	}
	.lap--four-tenths {
		width: 40%;
	}
	.push--lap--four-tenths {
		left: 40%;
	}
	.lap--five-tenths {
		width: 50%;
	}
	.push--lap--five-tenths {
		left: 50%;
	}
	.lap--six-tenths {
		width: 60%;
	}
	.push--lap--six-tenths {
		left: 60%;
	}
	.lap--seven-tenths {
		width: 70%;
	}
	.push--lap--seven-tenths {
		left: 70%;
	}
	.lap--eight-tenths {
		width: 80%;
	}
	.push--lap--eight-tenths {
		left: 80%;
	}
	.lap--nine-tenths {
		width: 90%;
	}
	.push--lap--nine-tenths {
		left: 90%;
	}
	.lap--one-twelfth {
		width: 8.333333333333332%;
	}
	.push--lap--one-twelfth {
		left: 8.333333333333332%;
	}
	.lap--two-twelfths {
		width: 16.666666666666664%;
	}
	.push--lap--two-twelfths {
		left: 16.666666666666664%;
	}
	.lap--three-twelfths {
		width: 25%;
	}
	.push--lap--three-twelfths {
		left: 25%;
	}
	.lap--four-twelfths {
		width: 33.33333333333333%;
	}
	.push--lap--four-twelfths {
		left: 33.33333333333333%;
	}
	.lap--five-twelfths {
		width: 41.66666666666667%;
	}
	.push--lap--five-twelfths {
		left: 41.66666666666667%;
	}
	.lap--six-twelfths {
		width: 50%;
	}
	.push--lap--six-twelfths {
		left: 50%;
	}
	.lap--seven-twelfths {
		width: 58.333333333333336%;
	}
	.push--lap--seven-twelfths {
		left: 58.333333333333336%;
	}
	.lap--eight-twelfths {
		width: 66.66666666666666%;
	}
	.push--lap--eight-twelfths {
		left: 66.66666666666666%;
	}
	.lap--nine-twelfths {
		width: 75%;
	}
	.push--lap--nine-twelfths {
		left: 75%;
	}
	.lap--ten-twelfths {
		width: 83.33333333333334%;
	}
	.push--lap--ten-twelfths {
		left: 83.33333333333334%;
	}
	.lap--eleven-twelfths {
		width: 91.66666666666666%;
	}
	.push--lap--eleven-twelfths {
		left: 91.66666666666666%;
	}
}
.lap--shown {
	display: none !important;
}
@media (max-width: 640px) {
	.lap--shown {
		display: block !important;
	}
}
@media (max-width: 640px) {
	.lap--hidden {
		display: none !important;
	}
}
@media (max-width: 960px) {
	.portable--one-whole {
		width: 100%;
	}
	.portable--one-half {
		width: 50%;
	}
	.push--portable--one-half {
		left: 50%;
	}
	.portable--one-third {
		width: 33.33333333333333%;
	}
	.push--portable--one-third {
		left: 33.33333333333333%;
	}
	.portable--two-thirds {
		width: 66.66666666666666%;
	}
	.push--portable--two-thirds {
		left: 66.66666666666666%;
	}
	.portable--one-quarter {
		width: 25%;
	}
	.push--portable--one-quarter {
		left: 25%;
	}
	.portable--two-quarters {
		width: 50%;
	}
	.push--portable--two-quarters {
		left: 50%;
	}
	.portable--three-quarters {
		width: 75%;
	}
	.push--portable--three-quarters {
		left: 75%;
	}
	.portable--one-fifth {
		width: 20%;
	}
	.push--portable--one-fifth {
		left: 20%;
	}
	.portable--two-fifths {
		width: 40%;
	}
	.push--portable--two-fifths {
		left: 40%;
	}
	.portable--three-fifths {
		width: 60%;
	}
	.push--portable--three-fifths {
		left: 60%;
	}
	.portable--four-fifths {
		width: 80%;
	}
	.push--portable--four-fifths {
		left: 80%;
	}
	.portable--one-sixth {
		width: 16.666666666666664%;
	}
	.push--portable--one-sixth {
		left: 16.666666666666664%;
	}
	.portable--two-sixths {
		width: 33.33333333333333%;
	}
	.push--portable--two-sixths {
		left: 33.33333333333333%;
	}
	.portable--three-sixths {
		width: 50%;
	}
	.push--portable--three-sixths {
		left: 50%;
	}
	.portable--four-sixths {
		width: 66.66666666666666%;
	}
	.push--portable--four-sixths {
		left: 66.66666666666666%;
	}
	.portable--five-sixths {
		width: 83.33333333333334%;
	}
	.push--portable--five-sixths {
		left: 83.33333333333334%;
	}
	.portable--one-seventh {
		width: 14.285714285714285%;
	}
	.push--portable--one-seventh {
		left: 14.285714285714285%;
	}
	.portable--two-sevenths {
		width: 28.57142857142857%;
	}
	.push--portable--two-sevenths {
		left: 28.57142857142857%;
	}
	.portable--three-sevenths {
		width: 42.857142857142854%;
	}
	.push--portable--three-sevenths {
		left: 42.857142857142854%;
	}
	.portable--four-sevenths {
		width: 57.14285714285714%;
	}
	.push--portable--four-sevenths {
		left: 57.14285714285714%;
	}
	.portable--five-sevenths {
		width: 71.42857142857143%;
	}
	.push--portable--five-sevenths {
		left: 71.42857142857143%;
	}
	.portable--six-sevenths {
		width: 85.71428571428571%;
	}
	.push--portable--six-sevenths {
		left: 85.71428571428571%;
	}
	.portable--one-eighth {
		width: 12.5%;
	}
	.push--portable--one-eighth {
		left: 12.5%;
	}
	.portable--two-eighths {
		width: 25%;
	}
	.push--portable--two-eighths {
		left: 25%;
	}
	.portable--three-eighths {
		width: 37.5%;
	}
	.push--portable--three-eighths {
		left: 37.5%;
	}
	.portable--four-eighths {
		width: 50%;
	}
	.push--portable--four-eighths {
		left: 50%;
	}
	.portable--five-eighths {
		width: 62.5%;
	}
	.push--portable--five-eighths {
		left: 62.5%;
	}
	.portable--six-eighths {
		width: 75%;
	}
	.push--portable--six-eighths {
		left: 75%;
	}
	.portable--seven-eighths {
		width: 87.5%;
	}
	.push--portable--seven-eighths {
		left: 87.5%;
	}
	.portable--one-nineth {
		width: 11.11111111111111%;
	}
	.push--portable--one-nineth {
		left: 11.11111111111111%;
	}
	.portable--two-nineths {
		width: 22.22222222222222%;
	}
	.push--portable--two-nineths {
		left: 22.22222222222222%;
	}
	.portable--three-nineths {
		width: 33.33333333333333%;
	}
	.push--portable--three-nineths {
		left: 33.33333333333333%;
	}
	.portable--four-nineths {
		width: 44.44444444444444%;
	}
	.push--portable--four-nineths {
		left: 44.44444444444444%;
	}
	.portable--five-nineths {
		width: 55.55555555555556%;
	}
	.push--portable--five-nineths {
		left: 55.55555555555556%;
	}
	.portable--six-nineths {
		width: 66.66666666666666%;
	}
	.push--portable--six-nineths {
		left: 66.66666666666666%;
	}
	.portable--seven-nineths {
		width: 77.77777777777779%;
	}
	.push--portable--seven-nineths {
		left: 77.77777777777779%;
	}
	.portable--eight-nineths {
		width: 88.88888888888889%;
	}
	.push--portable--eight-nineths {
		left: 88.88888888888889%;
	}
	.portable--one-tenth {
		width: 10%;
	}
	.push--portable--one-tenth {
		left: 10%;
	}
	.portable--two-tenths {
		width: 20%;
	}
	.push--portable--two-tenths {
		left: 20%;
	}
	.portable--three-tenths {
		width: 30%;
	}
	.push--portable--three-tenths {
		left: 30%;
	}
	.portable--four-tenths {
		width: 40%;
	}
	.push--portable--four-tenths {
		left: 40%;
	}
	.portable--five-tenths {
		width: 50%;
	}
	.push--portable--five-tenths {
		left: 50%;
	}
	.portable--six-tenths {
		width: 60%;
	}
	.push--portable--six-tenths {
		left: 60%;
	}
	.portable--seven-tenths {
		width: 70%;
	}
	.push--portable--seven-tenths {
		left: 70%;
	}
	.portable--eight-tenths {
		width: 80%;
	}
	.push--portable--eight-tenths {
		left: 80%;
	}
	.portable--nine-tenths {
		width: 90%;
	}
	.push--portable--nine-tenths {
		left: 90%;
	}
	.portable--one-twelfth {
		width: 8.333333333333332%;
	}
	.push--portable--one-twelfth {
		left: 8.333333333333332%;
	}
	.portable--two-twelfths {
		width: 16.666666666666664%;
	}
	.push--portable--two-twelfths {
		left: 16.666666666666664%;
	}
	.portable--three-twelfths {
		width: 25%;
	}
	.push--portable--three-twelfths {
		left: 25%;
	}
	.portable--four-twelfths {
		width: 33.33333333333333%;
	}
	.push--portable--four-twelfths {
		left: 33.33333333333333%;
	}
	.portable--five-twelfths {
		width: 41.66666666666667%;
	}
	.push--portable--five-twelfths {
		left: 41.66666666666667%;
	}
	.portable--six-twelfths {
		width: 50%;
	}
	.push--portable--six-twelfths {
		left: 50%;
	}
	.portable--seven-twelfths {
		width: 58.333333333333336%;
	}
	.push--portable--seven-twelfths {
		left: 58.333333333333336%;
	}
	.portable--eight-twelfths {
		width: 66.66666666666666%;
	}
	.push--portable--eight-twelfths {
		left: 66.66666666666666%;
	}
	.portable--nine-twelfths {
		width: 75%;
	}
	.push--portable--nine-twelfths {
		left: 75%;
	}
	.portable--ten-twelfths {
		width: 83.33333333333334%;
	}
	.push--portable--ten-twelfths {
		left: 83.33333333333334%;
	}
	.portable--eleven-twelfths {
		width: 91.66666666666666%;
	}
	.push--portable--eleven-twelfths {
		left: 91.66666666666666%;
	}
}
.portable--shown {
	display: none !important;
}
@media (max-width: 960px) {
	.portable--shown {
		display: block !important;
	}
}
@media (max-width: 960px) {
	.portable--hidden {
		display: none !important;
	}
}
@media (max-width: 1200px) {
	.desk--one-whole {
		width: 100%;
	}
	.desk--one-half {
		width: 50%;
	}
	.push--desk--one-half {
		left: 50%;
	}
	.desk--one-third {
		width: 33.33333333333333%;
	}
	.push--desk--one-third {
		left: 33.33333333333333%;
	}
	.desk--two-thirds {
		width: 66.66666666666666%;
	}
	.push--desk--two-thirds {
		left: 66.66666666666666%;
	}
	.desk--one-quarter {
		width: 25%;
	}
	.push--desk--one-quarter {
		left: 25%;
	}
	.desk--two-quarters {
		width: 50%;
	}
	.push--desk--two-quarters {
		left: 50%;
	}
	.desk--three-quarters {
		width: 75%;
	}
	.push--desk--three-quarters {
		left: 75%;
	}
	.desk--one-fifth {
		width: 20%;
	}
	.push--desk--one-fifth {
		left: 20%;
	}
	.desk--two-fifths {
		width: 40%;
	}
	.push--desk--two-fifths {
		left: 40%;
	}
	.desk--three-fifths {
		width: 60%;
	}
	.push--desk--three-fifths {
		left: 60%;
	}
	.desk--four-fifths {
		width: 80%;
	}
	.push--desk--four-fifths {
		left: 80%;
	}
	.desk--one-sixth {
		width: 16.666666666666664%;
	}
	.push--desk--one-sixth {
		left: 16.666666666666664%;
	}
	.desk--two-sixths {
		width: 33.33333333333333%;
	}
	.push--desk--two-sixths {
		left: 33.33333333333333%;
	}
	.desk--three-sixths {
		width: 50%;
	}
	.push--desk--three-sixths {
		left: 50%;
	}
	.desk--four-sixths {
		width: 66.66666666666666%;
	}
	.push--desk--four-sixths {
		left: 66.66666666666666%;
	}
	.desk--five-sixths {
		width: 83.33333333333334%;
	}
	.push--desk--five-sixths {
		left: 83.33333333333334%;
	}
	.desk--one-seventh {
		width: 14.285714285714285%;
	}
	.push--desk--one-seventh {
		left: 14.285714285714285%;
	}
	.desk--two-sevenths {
		width: 28.57142857142857%;
	}
	.push--desk--two-sevenths {
		left: 28.57142857142857%;
	}
	.desk--three-sevenths {
		width: 42.857142857142854%;
	}
	.push--desk--three-sevenths {
		left: 42.857142857142854%;
	}
	.desk--four-sevenths {
		width: 57.14285714285714%;
	}
	.push--desk--four-sevenths {
		left: 57.14285714285714%;
	}
	.desk--five-sevenths {
		width: 71.42857142857143%;
	}
	.push--desk--five-sevenths {
		left: 71.42857142857143%;
	}
	.desk--six-sevenths {
		width: 85.71428571428571%;
	}
	.push--desk--six-sevenths {
		left: 85.71428571428571%;
	}
	.desk--one-eighth {
		width: 12.5%;
	}
	.push--desk--one-eighth {
		left: 12.5%;
	}
	.desk--two-eighths {
		width: 25%;
	}
	.push--desk--two-eighths {
		left: 25%;
	}
	.desk--three-eighths {
		width: 37.5%;
	}
	.push--desk--three-eighths {
		left: 37.5%;
	}
	.desk--four-eighths {
		width: 50%;
	}
	.push--desk--four-eighths {
		left: 50%;
	}
	.desk--five-eighths {
		width: 62.5%;
	}
	.push--desk--five-eighths {
		left: 62.5%;
	}
	.desk--six-eighths {
		width: 75%;
	}
	.push--desk--six-eighths {
		left: 75%;
	}
	.desk--seven-eighths {
		width: 87.5%;
	}
	.push--desk--seven-eighths {
		left: 87.5%;
	}
	.desk--one-nineth {
		width: 11.11111111111111%;
	}
	.push--desk--one-nineth {
		left: 11.11111111111111%;
	}
	.desk--two-nineths {
		width: 22.22222222222222%;
	}
	.push--desk--two-nineths {
		left: 22.22222222222222%;
	}
	.desk--three-nineths {
		width: 33.33333333333333%;
	}
	.push--desk--three-nineths {
		left: 33.33333333333333%;
	}
	.desk--four-nineths {
		width: 44.44444444444444%;
	}
	.push--desk--four-nineths {
		left: 44.44444444444444%;
	}
	.desk--five-nineths {
		width: 55.55555555555556%;
	}
	.push--desk--five-nineths {
		left: 55.55555555555556%;
	}
	.desk--six-nineths {
		width: 66.66666666666666%;
	}
	.push--desk--six-nineths {
		left: 66.66666666666666%;
	}
	.desk--seven-nineths {
		width: 77.77777777777779%;
	}
	.push--desk--seven-nineths {
		left: 77.77777777777779%;
	}
	.desk--eight-nineths {
		width: 88.88888888888889%;
	}
	.push--desk--eight-nineths {
		left: 88.88888888888889%;
	}
	.desk--one-tenth {
		width: 10%;
	}
	.push--desk--one-tenth {
		left: 10%;
	}
	.desk--two-tenths {
		width: 20%;
	}
	.push--desk--two-tenths {
		left: 20%;
	}
	.desk--three-tenths {
		width: 30%;
	}
	.push--desk--three-tenths {
		left: 30%;
	}
	.desk--four-tenths {
		width: 40%;
	}
	.push--desk--four-tenths {
		left: 40%;
	}
	.desk--five-tenths {
		width: 50%;
	}
	.push--desk--five-tenths {
		left: 50%;
	}
	.desk--six-tenths {
		width: 60%;
	}
	.push--desk--six-tenths {
		left: 60%;
	}
	.desk--seven-tenths {
		width: 70%;
	}
	.push--desk--seven-tenths {
		left: 70%;
	}
	.desk--eight-tenths {
		width: 80%;
	}
	.push--desk--eight-tenths {
		left: 80%;
	}
	.desk--nine-tenths {
		width: 90%;
	}
	.push--desk--nine-tenths {
		left: 90%;
	}
	.desk--one-twelfth {
		width: 8.333333333333332%;
	}
	.push--desk--one-twelfth {
		left: 8.333333333333332%;
	}
	.desk--two-twelfths {
		width: 16.666666666666664%;
	}
	.push--desk--two-twelfths {
		left: 16.666666666666664%;
	}
	.desk--three-twelfths {
		width: 25%;
	}
	.push--desk--three-twelfths {
		left: 25%;
	}
	.desk--four-twelfths {
		width: 33.33333333333333%;
	}
	.push--desk--four-twelfths {
		left: 33.33333333333333%;
	}
	.desk--five-twelfths {
		width: 41.66666666666667%;
	}
	.push--desk--five-twelfths {
		left: 41.66666666666667%;
	}
	.desk--six-twelfths {
		width: 50%;
	}
	.push--desk--six-twelfths {
		left: 50%;
	}
	.desk--seven-twelfths {
		width: 58.333333333333336%;
	}
	.push--desk--seven-twelfths {
		left: 58.333333333333336%;
	}
	.desk--eight-twelfths {
		width: 66.66666666666666%;
	}
	.push--desk--eight-twelfths {
		left: 66.66666666666666%;
	}
	.desk--nine-twelfths {
		width: 75%;
	}
	.push--desk--nine-twelfths {
		left: 75%;
	}
	.desk--ten-twelfths {
		width: 83.33333333333334%;
	}
	.push--desk--ten-twelfths {
		left: 83.33333333333334%;
	}
	.desk--eleven-twelfths {
		width: 91.66666666666666%;
	}
	.push--desk--eleven-twelfths {
		left: 91.66666666666666%;
	}
}
.desk--shown {
	display: none !important;
}
@media (max-width: 1200px) {
	.desk--shown {
		display: block !important;
	}
}
@media (max-width: 1200px) {
	.desk--hidden {
		display: none !important;
	}
}
