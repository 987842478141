.card-item
  p()
  position relative
  display block
  margin 0
  padding 0 20px
  height 52px
  color $black
  background-color #fff

  +below('m')
    padding 0
    height 70px

  &:hover
    background-color $gallery

    .card-item__close
      display block
      
    +below('l')
      background-color transparent

  &__close
    display none
    size(31px)
    position absolute
    top 50%
    right 20px
    transform translate(0, -50%)
    cursor pointer
    border 1px solid $blueRibbon
    border-radius 50%

    svg
      width 16px
      height 16px
      position absolute
      top 50%
      left 50%
      margin -8px 0 0 -8px

    +below('l')
      display block

    +below('m')
      size(16px)
      border-radius 0
      border-color transparent
      transform translate(0,0)
      top 15px
      right 0


  &__input
    position absolute
    display none

  &__icon
    inline-block(top)
    size(31px)
    border-radius 50%
    transition all .3s cubic-bezier(.4, 1.34, .51, 1.01)

    &:before
      size(18px)
      border 1px solid #ccc
      border-radius 2px
      background-color white

  &__input:checked + .card-item__icon

    &:before
      border 1px solid $blackPearl

    &:after
      opacity 1
      transform scale(1)

  &__content
    display block
    padding 15px 0 0 0

  &__text-muted
    margin 0 0 0 6px

    +below('m')
      margin 0
      display block

  &__number
  &__status
    inline-block(top)
    margin 0


  &__number
    width 45%
    font-family $Muller
    font-size 16px
    color #000
    line-height (20/16)

    +below('m')
      display block
      width 100%

  &__status
    margin 0
    padding 0 0 0 10px

    +below('m')
      padding 0

  &__status-icon
    inline-block(top)
    margin-right 8px
