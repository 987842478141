.form-rubric
  display block
  background-color $white

  &__inner
    padding 32px 48px 40px 40px

    &--small
      padding 24px 24px 32px

  &__group
    position relative
    margin 0 0 23px 0

  &__label-text
    p()
    display block
    color $silverChalice
    margin 0 0 16px 0

    &--small
      margin 0 0 8px 0

  &__input
    width 432px
    height 49px

    &--fill
      width 100%

  &__textarea
    width 632px
    height 128px

    &--fill
      width 100%

  &__text
    h4()
    margin 0 0 24px 0

  &__footer
    @extend .cf
    position relative
