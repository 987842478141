// Clear the float on any element.
.cf
  &:after
    display table
    clear both
    content ''

clearfix()
  &:after
    display table
    clear both
    content ''

// remove default list styles
clearlist()
  margin 0
  padding 0
  list-style-type none

// Fix no working `-webkit-text-size-adjust: 100%;` in Android.
fix-text-size-adjust()
  background-image linear-gradient(transparent, transparent)

// Set element size
size($width, $height = $width)
  width $width
  height $height

// Set margin on left & right
margin-x($margin)
  margin-left $margin
  margin-right $margin

// Set margin on top & bottom
margin-y($margin)
  margin-top $margin
  margin-bottom $margin

// Set pading on left & right
padding-x($padding)
  padding-left $padding
  padding-right $padding

// Set padding on top & bottom
padding-y($padding)
  padding-top $padding
  padding-bottom $padding

// inline-block
inline-block($value)
  display inline-block
  vertical-align $value

// table-cell
table-cell($value)
  display table-cell
  vertical-align $value

//   _     _       _
//  | |   (_)_ __ | | _____
//  | |   | | '_ \| |/ / __|
//  | |___| | | | |   <\__ \
//  |_____|_|_| |_|_|\_\___/
//

link-base()
  font 500 12px/16px $baseFont
  color $linkColor


border-bottom-gray()
  border-bottom 1px solid #E4E4EC

//   _____
//  |_   _|   _ _ __   ___
//    | || | | | '_ \ / _ \
//    | || |_| | |_) |  __/
//    |_| \__, | .__/ \___|
//        |___/|_|

p()
  font-family $Muller
  font-size $font-size-p
  line-height $line-height-p
  font-weight 400

p-muted()
  font-family $Muller
  font-size $font-size-p
  line-height $line-height-p
  font-weight 400
  color $c_muted

p-small()
  font-family $Muller
  font-size $font-size-p-small
  line-height $line-height-p-small
  font-weight 400

link()
  font-family $Muller
  font-size $font-size-p
  line-height $line-height-p
  font-weight 400
  color $blueRibbon


h2()
  font-family $Muller
  font-size $font-size-h2
  line-height $line-height-h2
  font-weight 500

  +below(m)
    font-size $font-size-mobile-h2
    line-height $line-height-mobile-h2

h3()
  font-family $Muller
  font-size $font-size-h3
  line-height $line-height-h3
  font-weight 500

  +below(m)
    font-size $font-size-mobile-h3
    line-height $line-height-mobile-h3

h4()
  font-family $Muller
  font-size $font-size-h4
  line-height $line-height-h4
  font-weight 500

  +below(m)
    font-size $font-size-mobile-h4
    line-height $line-height-mobile-h4

input-text()
  font-family $Muller
  font-weight 400
  font-size $font-size-input
  line-height $line-height-input
