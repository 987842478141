.index
  padding 80px 100px
  min-height 100vh
  text-align left
  background #ECE9E9 -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#ECE9E9))
  background #ECE9E9 -moz-linear-gradient(top, #fff, #ECE9E9)
  background-size cover
  font-size 0

  &__title
    margin 0
    font 400 30px/30px $Muller

  &__content
    padding-top 148px

  &__text
    font-family $Muller
    font-weight 300
    font-size 16px
    line-height 22px
    color #000

  &__sub-title
    margin 0
    border-left none
    border-right none
    font 500 18px/66px $Muller

    &--test-color
      color #3e3e46
