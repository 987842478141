.btn-icon
  display -webkit-box
  display -webkit-flex
  display -ms-flexbox
  display flex
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;

  &__text

    &--line-dotted
      border-bottom 1px dotted currentColor

    &--big
      font-size $font-size-h4
      font-weight 500
      line-height $line-height-mobile-h3

  &__icon
    margin-right 8px
    fill currentColor