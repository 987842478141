.pagination
  display block
  font-size 0
  margin 40px 0 0 0
  text-align center

  &__link
    padding 13px 16px 13px 14px
    cursor pointer
    font 400 14px / 28px $baseFont
    color #56565E
    border 1px solid $borderColor
    border-radius 50%

    &:last-child
      padding 13px 14px 13px 16px

  &__name
    size(40px)
    padding 9px 0 10px 0
    font-family $Muller
    font-size $font-size-a
    line-height $line-height-a
    font-weight 400
    color $blueRibbon
    border 1px solid $borderColor
    border-radius 50%

  &__item
    text-align center
    margin 0 16px 0 0

  &__link
    text-align center

  &__item.active

    .pagination__name
      border-color $blueRibbon


  &__item
    inline-block(middle)
    cursor pointer

  &__name
  &__link
  &__list
    inline-block(top)

