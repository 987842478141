.filter-calls
  display block
  font-size 0
  margin 0

  +below('m')
    padding 0 0 0 16px
    margin 0

  &__wrapper
    inline-block(top)
    margin 0 0 0 48px
    font-size 0

    +below('m')
      margin 10px 0 0 0
      display block

    &--block
      margin 25px 0 0 0
      display block

  &__search
    inline-block(top)
    position relative
    max-width 344px
    width 100%

  &__group
    inline-block(top)
    margin 0 0 0 48px

    &:first-child
      margin 0

  &__label
  &__field
    inline-block(middle)
    position relative

  &__label
    p()
    margin 0 32px 0 0

  &__datepicker
    width 182px
