.icon

  &--right
    margin 0 8px 0 0

  /*==============================
  =            COLORS            =
  ==============================*/

  &--blue
    fill $blueRibbon

  &--white
    fill #fff!important

  &--redRibbon
    fill $redRibbon

  &--silver
    fill $silverChalice

  &--bluishGreen
    fill $bluishGreen

  &--black
    fill $black

  /*-----  End of COLORS  ------*/

  /*==============================
  =            SIZES             =
  ==============================*/

  &--person
    size(24px)
    fill $blueRibbon

  &--lock
    size(24px)

  &--logo
    width 207px
    height 64px

  &--arrow-back
    width 8px
    height 12px
    fill $blueRibbon

  &--arrow-forward
    width 8px
    height 12px
    fill $blueRibbon
    transform rotate(180deg)

  &--arrow-down
    width 8px
    height 12px
    fill $blueRibbon
    transform rotate(-90deg)

  &--add
    size(24px)
    fill #fff

  &--search
    size(24px)

  &--calendar
  &--call
    size(24px)

  &--filter
    size(24px)

  &--close
    size(16px)
    fill $blueRibbon

  &--close-big
    size(24px)

  &--settings
  &--help
  &--dashboard
    size(24px)

  &--call-small
    size(18px)


  &--reload
  &--notifications
  &--check-circle-balance
  &--update
  &--sentiment
    size(24px)
    position relative
    top -2px
    fill #fff
    vertical-align middle


  &--update
    size(24px)
    fill #fff
    vertical-align middle

  &--minutes
  &--forward
  &--calls
    size(144px)

  &--forward
    fill $blueRibbon

  &--calls
    inline-block(top)
    background url('../images/calls.svg') no-repeat

  &--help
    size(24px)
    fill $blueRibbon

  &--message
    size(20px)
    fill $blueRibbon

  &--credit-card
    size(24px)

  &--counter-minus
  &--counter-plus
    size(12px)

  &--check
    width 32px
    height 32px

  &--check-circle
    size(20px)
    fill #14C86E

  &--attention
    width 28px
    height 28px

  &--down
    width 24px
    height 31px

  &--avatar
    size(48px)
    inline-block(top)

  &--shape
    -webkit-align-self flex-start
    -ms-flex-item-align start
    align-self flex-start
    margin-top 2px
    width 20.5px
    height 17px

  &--edit
    width 32px
    height 32px

  &--basket-del
    width 14px
    height 18px

  &--phone
    width 13px
    height 22px

  &--phone2
    -webkit-align-self flex-start
    -ms-flex-item-align start
    align-self flex-start
    margin-top 2px
    width 14px
    height 16px

  &--clip
    -webkit-align-self flex-start
    -ms-flex-item-align start
    align-self flex-start
    width 10px
    height 20px

  &--excel
  &--pdf
    -webkit-align-self flex-start
    -ms-flex-item-align start
    align-self flex-start
    width 17px
    height 20px

  &--del
    size(16px)

  &--bubble
  &--copy
  &--off
    size(20px)

  &--end-call
  &--transfer-call
    width 24px
    height 20px

  &--transfer-call-small
    width 20px
    height 15.4px

/*-----  End of SIZES   ------*/
