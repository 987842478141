.alert
  @extend .cf
  position relative
  display block
  cursor pointer

  &--btn
    inline-block(top)
    height 40px
    line-height 40px
    padding 0 20px
    cursor pointer
    
  &--hide
    opacity 0
    visibility hidden
    transform translateY(9999px)
    transition all 0.3s ease
    display none!important

  /*==============================
  =            COLOR             =
  ==============================*/

  &--big
    min-height 102px
    margin-bottom 16px
    padding 23px 32px

    +below('m')
      padding 20px 16px
      margin 0
      text-align center
  
  &--success
    background-color $jade
    
  &--warning
    background-color $brightSun
    
  &--danger
    background-color $blueRibbon

  /*-----  End of COLOR  ------*/

  /*==============================
  =            TYPE              =
  ==============================*/

  &__title
    margin 0 0 4px 0
    font-family $Muller
    font-weight 500
    color #fff
    font-size 20px
    line-height 28/20

  &__text
    margin 0
    font-family $Muller
    font-weight 400
    font-size 16px
    line-height 22/16
    color #FFF

  /*-----  End of TYPE  ------*/

  &__close
    position absolute
    right 8px
    top 8px
    cursor pointer

  &__icon-wrap
    size(56px)
    inline-block(middle)
    float left
    border-radius 50%
    border 1px solid #fff
    padding 12px

    +below('m')
      display block
      float none
      margin 0 auto 16px auto

  &__content
    padding-left 72px

    +below('m')
      padding-left 0

  &__content-wrap
    padding-right 10px

    +below('m')
      padding 0

  &__btn-wrap
    padding 5px 0 0 0
    text-align right

    +below('m')
      padding 20px 0 0 0
      text-align center
