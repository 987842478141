/*==============================
=            LINKS             =
==============================*/

a
  text-decoration none

.link
  padding 0 0 2px 0
  font-family $Muller
  font-size $font-size-a
  line-height $line-height-a
  font-weight 400
  text-decoration none
  color $blueRibbon
  transition $time ease
  border-bottom 1px solid transparent

  &:hover
    border-bottom 1px solid $blueRibbon

    +below('l')
      border-bottom 1px solid transparent

  &:active
    color #000
    border-bottom 1px solid #000

    +below('l')
      border-bottom 1px solid #000

  &--white
    color #fff

    &:hover
    &:active
      color #fff
      border-bottom 1px solid #fff

  &--gray
    color $silverChalice

    &:hover
      border-color $silverChalice
    &:active
      border-color $black

  &--small
    font-size $font-size-a-small
    line-height $line-height-a-small

  &--icon:hover
  &--icon:active
    border-bottom 1px solid transparent

  &--icon:hover span
  &--icon:hover .link__name
    border-bottom 1px solid $blueRibbon

  &--icon svg
    transition $time fill

  &--icon:active svg
    fill $black

  &--icon:active span
    border-bottom 1px solid $black

  &--icon-left span
  &--icon-left .link__name
    margin 0 0 0 10px
    transition $time ease
    border-bottom 1px solid transparent

  &--black
    color #000

  &--btn
    font-family: $Muller
    font-weight: 400;
    text-decoration: none;
    white-space: nowrap;
    border-top none
    border-left none
    border-right none
    background none
    outline: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  &--tap
    pointer-events none
    transition none

    +below('l')
      pointer-events auto
      color $blueRibbon

      &:hover
      &:active
        border-bottom none


/*-----  End of LINKS  ------*/

/*==============================
=   HEADING, PARAGRAPH, SPAN   =
==============================*/

h1
.h1
h2
.h2
h3
.h3
h4
.h4
p
  display block
  color $black
  font-family $Muller

h1
.h1
.page-title
  display block
  margin 24px 0 24px 0
  font-family $Agipo
  font-size $font-size-h1
  line-height $line-height-h1
  font-weight 400

h1.page-title
.page-title
  margin 0 0 24px 0

h1.title-display
.title-display
  display block
  width 768px
  font-family $Muller
  font-size $font-size-title-display
  line-height $line-height-title-display
  margin 0 0 24px
  border-bottom 1px solid $borderColor
  color #000

h2
.h2
  margin 24px 0
  font-size $font-size-h2
  line-height $line-height-h2
  font-weight 500

  +below(m)
    font-size $font-size-mobile-h2
    line-height $line-height-mobile-h2

h3
.h3
  margin 24px 0
  font-size $font-size-h3
  line-height $line-height-h3
  font-weight 500

  +below(m)
    font-size $font-size-mobile-h3
    line-height $line-height-mobile-h3

h4
.h4
  margin 24px 0
  font-size $font-size-h4
  line-height $line-height-h4
  font-weight 500

  +below(m)
    font-size $font-size-mobile-h4
    line-height $line-height-mobile-h4


p
  margin 22px 0
  font-size $font-size-p
  line-height $line-height-p
  font-weight 400

p.muted
  font-size $font-size-p-muted
  line-height $line-height-p-muted
  font-weight 400
  color $silverChalice

.text-muted
  font-family $Muller
  font-size $font-size-p-muted
  line-height $line-height-p-muted
  font-weight 400
  color $silverChalice

.text-small
  font-family $Muller
  font-size $font-size-p-muted
  line-height 1
  font-weight 400
  color $silverChalice

span
  font-size inherit
  font-family inherit
  line-height inherit

/*-----  HEADING, PARAGRAPH, SPAN  ------*/


/*==============================
=            LISTS             =
==============================*/

.dotted-list
  margin 22px 0 32px 0
  font-family $Muller
  font-weight 400

  li
    position relative
    display block
    padding 0 0 0 38px
    margin 0 0 8px 0
    font-family $Muller
    font-size 16px
    line-height (24/16)

    &:before
      content '\2022'
      position absolute
      top -2px
      left 10px
      font-size 30px
      line-height 30px
      font-family $Muller

.numbered-list
  margin 22px 0 32px 0
  counter-reset item
  font-family $Muller
  font-weight 400

  li
    position relative
    display block
    padding 0 0 0 38px
    margin 0 0 8px 0
    font-family $Muller
    font-weight 400
    font-size 16px
    line-height (24/16)
    color #000

    &:before
      content counter(item) ". "
      counter-increment item
      position absolute
      left 10px
      font-family $Muller
      font-weight 400
      font-size 16px
      line-height (24/16)

/*-----   End of LISTS   ------*/

.selected-text
  margin 0
  padding 20px 32px 28px 32px
  background transparent
  border-radius 4px
  font-family $Muller
  font-weight 400
  font-size 16px
  line-height (22/16)
  border 1px dashed #0055FF
  color #000
