.phone-duration

  &__condition
    display inline-block
    margin-right 9px
    width 8px
    height 8px
    background $silver
    vertical-align middle
    border-radius 50%

    &.is-active
      background $jade

  &__value
    display inline-block
    font-size $font-size-h2
    font-weight 500
    line-height 1.25
    vertical-align middle
