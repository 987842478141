.profile-info
  margin 0

  &__group
    margin 0 0 20px 0

  &__label
    display block
    margin 0 0 4px 0
    font-family $Muller
    font-size $font-size-p-small
    line-height $line-height-p-small
    font-weight 400
    color $silverChalice

  &__text
    display block
    margin 0
    font-family $Muller
    font-size $font-size-p
    line-height $line-height-p
    font-weight 400
    color $black

  &__link-exit
    margin-left 10px
