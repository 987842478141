.checkbox
  p()
  display block
  position relative
  color $black
  cursor pointer
  margin 10px 0
  padding 5px 0 0 40px

  &--sub
    padding 4px 0 0 40px
    margin 6px 0
  
    &:hover .checkbox__icon
      background-color transparent!important

    .checkbox__icon
      size(20px)
      position absolute
      top 16px
      background-color transparent
  
    .checkbox__icon:after
    .checkbox__icon:before
      top 0
      left 0

    .checkbox__input:checked + .checkbox__icon
      background-color #eee

    .checkbox__indicator:before
    .checkbox__indicator:after
      content ''
      left 0
      top 0

    .checkbox__text
      display block
      margin 1px 0 0 0


  &:hover
    .checkbox__icon:before
      border-color $silverChalice

    .checkbox__icon
      background-color #ccc

  &__input
    position absolute
    display none

  &__icon
    inline-block(middle)
    size(32px)
    position absolute
    top 50%
    left 0
    transform translateY(-50%)
    text-align left
    border 1px solid transparent
    border-radius 4px
    background-color #eee
    transition all .3s cubic-bezier(.4,1.34,.51,1.01)


    &:after
    &:before
      content ''
      position absolute
      left 5px
      top 5px
      background-position center center
      background-repeat no-repeat
      transition all .3s cubic-bezier(.4,1.34,.51,1.01)

    &:after
      size(16px)
      background-image url('../images/check.svg')
      background-color white
      opacity 0
      transform scale(0)
      margin 2px

    &:before
      size(18px)
      border 1px solid #ccc
      border-radius 2px
      background-color white

  &__input:checked + .checkbox__icon

    &:before
      border 1px solid $blackPearl

    &:after
      opacity 1
      transform scale(1)

  &__text
    vertical-align middle
    padding 0

  &__text-muted
    margin 0 0 0 6px

    +below('m')
      margin 0
      display block

