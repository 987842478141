.counter
  inline-block(top)
  width 162px
  vertical-align top
  white-space nowrap

  &--show
    display block!important

  &__wrap
    inline-block(top)
    font-size 0
    position relative
    height 30px
    border-radius 3px

  &__input
    border none

  &__label-wrapper
    inline-block(top)
    width auto
    min-width 100px
    height 28px
    -webkit-appearance none
    text-align center
    color black
    font 400 14px / 28px $baseFont
    outline 0

    .counter__label
      line-height 34px

  &__btn
    inline-block(top)
    size(29px)
    padding 8px 0 0 1px
    cursor pointer
    text-align center
    text-decoration none
    border 1px solid $blueRibbon
    border-radius 50%
    transition all $time

    svg
      transition fill $time ease

    &:hover
      background-color $smalt
      border-color $smalt

      svg
        fill #fff

    &--disabled
      pointer-events none
      border-color #EEEEEE
      background-color #EEEEEE

      &:hover
        border-color #EEEEEE
        background-color #EEEEEE

        svg
          fill #000

      svg
        fill #000!important

    &--plus
      right 0

      svg
        fill $blueRibbon

    &--minus
      left 0

  &__label::selection
  &__label-text::selection
    background transparent

  &__label
  &__value
    padding 0
    font-family $Muller
    font-weight 400
    font-size 16px
    line-height 20/16
    color #000

  &__label
    padding 0
    padding-left 0!important

  &__value
    line-height 28px


