.settings
  display block

  &__title
    h2()
    margin 0 0 20px 0

  &__title-description
    margin-left 16px
    
    +below('m')
      display block
      margin 4px 0 0 0
      font-size 12px

  &__text
    p()
    margin 0 0 8px 0
    color $silverChalice

  &__radio-text
    padding 0

  &__radio-text--selected
    padding 2px
    background-color #FFD249
    text-decoration underline
    white-space nowrap

  &__group
    margin 0 0 48px 0

  &__group--personal-data
    max-width 344px
    width 100%

  &__services-item
    margin 0 0 24px 0

  &__box
    font-size 0
    border 1px solid #eee

  &__box--textarea
    display none
    margin 0

  &__box--show
    display block

  &__box-item
    display none
    width 50%
    min-height 110px
    padding 16px 24px 20px 20px
    background-color #fff
    border-right 1px solid #eee

    +below('m')
      width 100%
      padding 12px 8px 12px 8px
      border-bottom 1px solid #eee

    &:last-child
      border-right 0
      
  &__box-item--open
    display inline-block
    animation fadeInFromNone 0.5s ease-out

  &__textarea
    min-height 110px
    animation fadeInFromNone 0.5s ease-out

    +below('l')
      min-height 90px

    +below('m')
      min-height 180px
    
  &__textarea--close
    display none
    
  &__icon-lock
    position relative
    top -3px
    vertical-align middle
    margin-right 4px

  &__bottom-panel
    height 40px
    border-top 1px solid #eee
    line-height 40px
    text-align center

  &__bottom-panel-link
    display block
    color $blueRibbon
    background-color #F6F6F6
    line-height 40px
    padding 0
    border none!important
    cursor pointer
    
    &:hover
      background-color: #EEEEEE
    
    &:hover
    &:active
      color $blueRibbon!important
      border none!important

      
@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
