.admin-info
  margin 0

  &--aside
    margin 64px 0 0 0

    .admin-info__wrapper
      margin 40px 0 0 0

  &__wrapper
    margin 20px 0 0 0

  &__row
  &__group
    margin 0 0 25px 0

  &__item
    inline-block(top)

    & + &
      margin 0 0 0 35px

  &__item--big

    .admin-info__label
      font-size $font-size-p
      line-height $line-height-p
      color $black

    .admin-info__text
      h2()


  &__label
    display block
    margin 0 0
    font-family $Muller
    font-size $font-size-p-small
    line-height $line-height-p-small
    font-weight 400
    color $silverChalice

  &__text
    display block
    margin 0
    font-family $Muller
    font-size $font-size-p
    line-height $line-height-p
    font-weight 400
    color $black
