.content
  width 1154px
  display block

  &--operator
    width 100%
    padding 48.3px 49px 48px 40px
    background-color #fff

  &--instructions
    width 100%
    padding 40px 96px 50px 40px
    background-color #fff

    +below('l')
      padding 40px 32px 50px

    +below('m')
      padding 40px 0 50px 16px

  &--admin
    margin 0 0 80px 0
    //padding 0 0 80px 0

  &--account
    width auto
    max-width 864px
    min-width 320px

  &--onboarding
    width auto
    max-width 960px
    min-width 320px
    margin 32px auto 64px
    background-color #fff

    +below('l')
      margin 0 auto

  &--normal
    width 100%
    padding 2px 68px 2px 40px
    background-color #fff

    +below('l')
      margin 0
      padding 2px 32px 2px

    +below('m')
      padding 2px 10px 2px 16px

    .h1
      margin 48px 0 24px 0

    .page-title
      margin 48px 0 8px 0

    .h3
      margin 32px 0 12px 0

    p
      margin 12px 0

  &--normal:last-child
    padding 2px 68px 32px 40px

    +below('l')
      margin 0
      padding 2px 32px 2px 32px

    +below('m')
      padding 2px 10px 2px 24px


  &--full
    width 100%
    padding 2px 68px 2px 40px
    background-color #fff
    margin 0

    +below('l')
      margin 0
      padding 2px 32px 2px

    +below('m')
      padding 2px 0 2px 16px

  &--settings
    width 100%
    padding 40px 40px 50px 40px
    background-color #fff
    margin 0 0 64px 0 // исправить выпадающий margin

    +below('l')
      margin 0
      padding 40px 32px 50px

    +below('m')
      padding 40px 0 50px 16px
