.audio
  display block

  +below('l')
    size(48px)

  +below('m')
    size(40px)
  
  .mejs-controls .mejs-time-rail .mejs-time-slider
  .mejs-container .mejs-controls .mejs-time
    display none
