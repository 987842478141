.page-instruction
  font-family $Muller

  &__title
    h2()
    margin 0 0 20px

  &__subtitle
    p()
    margin 0
    padding 0
    color $doveGray

  &__header
    margin-bottom 30px

  &__main
    margin-bottom 48px



.instruction
  font-family $Muller

  &__tool-panel
    margin-bottom 20px

  &__item
    margin-bottom 40px

    &--small
      margin-bottom 28px

    &--box
      margin-bottom 16px




.instruction-item
  position relative
  z-index 5
  border 1px solid $gallery
  background $white
  overflow hidden
  transition box-shadow .3s ease

  &.is-collapsed &__header
    padding-bottom 16px

  &.is-collapsed &__header:before
    border-top-color $black
    transform rotate(-90deg)

  &.is-collapsed &__header &__title
    margin-bottom 0

  &.is-collapsed &__controls
  &.is-collapsed &__panel
    display none

  &__title
    margin 0 10px 12px 0
    padding 0
    font-size $font-size-h4
    font-weight 500
    line-height $line-height-mobile-h3

    &--number
      margin-bottom 16px

  &:hover
    box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.3)
    transition box-shadow .3s ease

  &__controls
    position absolute
    bottom 27px
    right 24px
    z-index 6
    opacity 0
    transition opacity .3s ease

    +below('l')
      position initial
      padding 0 10px 20px
      opacity 1 !important

  &:hover &__controls
    opacity 1
    transition opacity .3s ease

  &__header
    padding 16px 58px 0 16px

    &:before
      content ''
      position absolute
      top 27px
      right 24px
      z-index 5
      border-left 6px solid transparent
      border-top 7px solid $silverChalice
      border-right 6px solid transparent
      transition transform .3s ease

    +below('m')
      padding 16px 10px 0

  &__panel
    padding 0 96px 20px 16px

    & > *:last-child
      margin-bottom 0

    +below('m')
      padding 16px 10px 20px

  &__row
    margin-bottom 16px

    &:last-child
      margin-bottom 0

  &__user-content
    margin-bottom 26px

    &--small
      margin-bottom 16px

  &__number
    margin-bottom 20px

  &__section
    margin-bottom 24px
    padding-bottom 32px
    border-bottom 1px solid $alto

    &--first
      margin-bottom 0
      border-bottom 0

  &__footer
    padding-bottom 15px
    text-align right

    +below('small-mobile')
      text-align center

    .btn:first-child
      margin-right 8px

      +below('m')
        margin-right 0
        margin-bottom 8px

  &--form
    margin-bottom 16px
    background #f6f6f6

  &--form &__header &__title
    margin-bottom 16px

  &--no-box
    padding 0
    border none
    cursor default

    &:hover
      box-shadow none

  &--no-box &__panel
    padding 0




.instruction-item-user-content
  font-family $Muller
  font-size 14px
  line-height 20px
  color $blackPearl

  p
  ul
  ol
  a
    font-size inherit
    line-height inherit
    color inherit

  p
  ul
  ol
  li
    margin 0 0 12px
    padding 0

  a
    text-decoration underline

  ul
    list-style-type none

    li:before
      content ''
      display inline-block
      margin 0 4px 0 2px
      width 2px
      height 2px
      vertical-align super
      background $black
      border-radius 50%

  ol
    list-style-type decimal
    list-style-position inside

  *:last-child
    margin-bottom 0

  &--bubble
    position relative
    margin-bottom 15px
    padding 9px 14px
    border 2px solid #ddd
    border-radius 5px 5px 5px 0
    background #fff

    &:before
      content ''
      position absolute
      bottom -16px
      left -2px
      border-left 16px solid #ddd
      border-bottom 16px solid transparent

    &:after
      content ''
      position absolute
      bottom -11px
      left 0
      border-left 11px solid #fff
      border-bottom 11px solid transparent




.instruction-item-number

  &__item
    display inline-block
    margin-right 20px
    vertical-align middle

    &--medium
      margin-right 16px

    &--small
      margin-right 8px

    &:last-child
      margin-right 0

      +below('m')
        display block
        padding-left 25px

  &__text
    font-size $font-size-p-muted
    font-weight 500
    line-height $line-height-p-muted



.instruction-item-controls
  clearfix()

  &__item
    float left
    width 32px
    height 32px
    line-height 40px
    text-align center

  &__btn
    display inline-block
    cursor pointer



.instruction-item-data
  position relative
  display -webkit-box
  display -webkit-flex
  display -ms-flexbox
  display flex
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  margin-bottom 10px
  cursor pointer

  &:last-child
    margin-bottom 0

  &__item
  &__controls
    display inline-block
    vertical-align middle

  &__controls
    opacity 0
    transition opacity .3s ease

    +below('l')
      opacity 1 !important

  &:hover &__controls
    opacity 1
    transition opacity .3s ease


  &__added
    display inline-block

  &__show

    &.is-hidden
      display none

  &__form
    clearfix()

    &.is-hidden
      display none

  &__field-wrap
    float left
    margin-bottom 8px
    padding-right 24px
    width 50%

    &:last-child
      padding-right 0

    +below('m')
      width 100%

  &__field-note
    margin 8px 0 0
    font-size $font-size-p-small
    line-height $line-height-p-small
    color $doveGray