.settigs-panel-confirm
  position fixed
  bottom 0
  left 0
  right 0

  opacity 0
  visibility hidden
  transform translateY(64px)
  transition all 0.3s ease

  width 100%
  height 64px
  background-color $gallery
  z-index 5
  
  +below('l')
    background-color transparent
  
  +below('m')
    transform translateY(48px)
    height 48px

  &--show
    visibility visible
    opacity 1
    transform translateY(0)
    pointer-events auto

  &__btn-group
    width auto
    padding 12px 40px
    background $blueRibbon

    +below('l')
    
      margin 0 32px
      padding 12px 40px

    +below('m')
      margin 0
      padding 8px 16px
    
  &__btn-primary
  
    +below('m')
      height 32px
      padding 0 20px
      font-size 14px
      line-height 32px

  &__btn-reset
    float right
    color #fff
    background-color transparent!important

    &:active
    &:hover
      color #fff!important
      background-color transparent!important

    +below('m')
      height 32px
      padding 0
      font-size 14px
      line-height 32px
    
  &__icon-close
    size(24px)
    fill #fff
    
