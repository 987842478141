.phone
  font-family $Muller

  &__header
    display table
    margin-bottom 13px
    padding 24px
    width 100%
    background $white

    &-item
      display table-cell
      vertical-align middle

      &--right
        text-align right

  &__record
    padding-top 6px
    height 47px

  &__err-call
    font-size 14px
    color $redRibbon
    border none
    background none
