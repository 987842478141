.page-list
  inline-block(top)
  width 100%
  font-size 0
  padding 0
  margin 0
  list-style none

  &__item
    margin 0 0 20px 0

  &__name
    inline-block(top)
    border 1px solid transparent
    font 300 16px/24px $Muller
    color black
    transition .1s ease
    overflow hidden
    text-overflow ellipsis

    &:hover
      position relative
      color $blueRibbon

      &:after
        content ''
        position absolute
        left 0
        right 0
        bottom 0
        width 100%
        height 1px
        border-bottom 1px solid $borderBottomColor

  &__additional-name
    inline-block(top)
    border 1px solid transparent
    font 300 16px/24px $Muller
    color black
    transition .1s ease
    overflow hidden
    text-overflow ellipsis

    &:hover
      position relative
      color $blueRibbon

      &:after
        content ''
        position absolute
        left 0
        right 0
        bottom 0
        width 100%
        height 1px
        border-bottom 1px solid $borderBottomColor
