.user-status
  p()
  //margin 0 0 4px 0
  line-height 16px
  display block
  position relative
  padding 0 0 0 18px

  &:before
    content ''
    size(8px)
    position absolute
    top 50%
    left 0
    transform translate(0, -50%)
    display block
    border-radius 50%

  &--nok
    color $redRibbon

    &:before
      background $redRibbon

  &--ok
    color $jade

    &:before
      background $jade
