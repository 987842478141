.filter-admin
  display block
  font-size 0
  margin 0 0 25px 0

  &__wrapper
    inline-block(top)
    margin 0 0 0 48px
    font-size 0

    &--block
      margin 25px 0 0 0
      display block

  &__search
    inline-block(top)
    position relative
    width 384px

  &__group
    inline-block(top)
    margin 0 0 0 48px

    &:first-child
      margin 0

  &__label
  &__field
    inline-block(middle)
    position relative

  &__label
    p()
    margin 0 32px 0 0

  &__datepicker
    width 182px
