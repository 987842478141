.onboarding

  &__content
    min-height 400px
    padding 64px 0
    text-align center
    background-color #fff

    +below('l')
      min-height 400px
      padding 28px 0 64px 0

    +below('m')
      padding 0 0 64px 0

  &__content-title
    margin 0 0 24px 0

  &__content-text-small
    font-size 16px
    line-height 22px

  &__services
    margin 8px 0 54px
    font-size 0

  &__services-item
    inline-block(top)
    width 33.3334%
    position relative
    padding 0 10px
    text-align center

    +below('l')
      padding 0

    +below('m')
      display block
      width 50%
      padding 0
      margin 0 auto 28px auto

    +below('small-mobile')
      width 80%

    &:last-child .onboarding__services-item-icon-wrap:after
      content none

  &__services-item-icon-wrap
    position relative
    text-align center
    margin 0 0 16px 0

    &:after
      content ''
      position absolute
      right 0
      top 50%
      transform translate(50%,0)
      display block
      border-top 2px dotted $blueRibbon
      width 48px
      height 2px

    +below('m')

      &:after
        content none

  &__services-item-text
    p()
    color $black
    text-align center

  &__selection-wrap
    margin 0 0 40px 0
    display block

  &__select
    margin 0 auto 40px auto

  &__calc-form-wrapper
    padding 40px 20px 56px 20px
    background #FFF
    border 1px solid #0055FF
    box-shadow 0px 0px 16px 0px #CCCCCC
    border-radius 8px
