.call
  position relative
  display block
  padding 20px 40px 22px 32px
  margin 16px 0
  background-color #fff

  &:last-child
    margin-bottom 1px

  +below('l')
    padding 0 40px 0 32px
    margin 24px 0

  +below('m')
    padding 0 6px 0 12px

  &--positive
    border-left 4px solid $brightSun
    
  &--listened
    border-left 4px solid $alto

  &--negative
    border-left 4px solid $redRibbon

  &--standard
    border-left 4px solid $resolutionBlue

  &__top
    @extend .cf
    display block
    margin 0 0 8px 0

  &__title
    font-family $Muller
    inline-block(top)
    font-size 16px
    line-height (20/16)

    & + &
      margin-left 12px

    &--phone
      padding-right 8px

  &__time
    font-family $Muller
    font-weight 400
    font-size 14px
    line-height (20/14)
    display block
    color #A0A0A0

  &__text
    margin 8px 0 0 0
    font-family $Muller
    font-size 14px
    line-height (20/14)
    color #666

  &__link
    display block
    margin 0 0 4px 0

  &__link-phone
    p()
    display inline
    color #000
    white-space nowrap

    +below('l')
      display inlin
  &__audio
    position absolute
    top 10px
    width 365px
    right -(@width - 20)

    +below('l')
      margin 10px 0
      position relative
      right 0
      top 0
