.payment
  display block

  &__title
    h2()
    margin 0 0 32px 0

    +below('m')
      margin 0 0 20px 0

  &__title--small
    h2()
    margin 0 0 16px 0

  &__title-description
    margin-left 16px

  &__text
    p()
    margin 0 0 8px 0
    color $silverChalice

  &__status
    @extend .cf

  &__status-chart
    width 45%
    float left
    padding 0 0 40px 0
    font-size 0

    +below('xl')
      float none
      width 100%

    +below('1080px')
      width 100%
      float none
      display block

    +below('l')
      width 50%
      float left
      padding 0 0 40px 0
      font-size 0

    +below('770px')
      width 100%
      float none
      display block

    +below('m')
      padding 0 0 24px 0

  &__status-wrapper
    margin 0 0 40px 0
    border-bottom 1px solid $borderColor

    +below('m')
      margin 0 0 48px 0
      border-bottom none

  &__calc-wrapper
    padding 0 0 40px 0
    margin 0 0 24px 0
    border-bottom 1px solid $borderColor

    +below('m')
      padding 0
      margin 0 0 32px 0
      border-bottom none

  &__status-activity
    float right
    width 55%
    padding 24px 24px 32px 32px
    margin 0 0 40px 0

    background-color #F6F6F6

    +below('xl')
      float none
      width 388px
      display block

    +below('l')
      float right
      width 50%
      padding 24px 24px 32px 32px
      margin 0 0 40px 0

    +below('770px')
      float none
      width 388px
      display block

    +below('m')
      width 100%
      margin 0

  &__status-activity-title
    margin 0 0 4px 0
    font-family $Muller
    font-weight 500
    font-size 20px
    line-height (28/20)
    color #000

  & &__status-activity-toggle
    padding 0 96px 0 0
    background-color transparent
    border-color transparent

    +below('m')
      padding 0

    &:hover
    &:active
      background-color transparent
      border-color transparent

  & &__status-activity-switch-wrap
    top 0
    right 0

  &__btn-wrap
    display block

  &__options-wrapper
    margin 0 0 32px 0

  &__checkbox-wrapper
    margin 0 0 24px 0

  &__checkbox
    inline-block(top)
    margin 0

  & &__status-activity-text
    margin 0 0 16px 0
    font-family $Muller
    font-size 16px
    line-height (22/16)
    font-weight 400
    color #000

    +below('l')
      height auto

    +below('m')
      height auto

  &__deposit-wrapper
    margin 0 0 32px 0
