.call-preview
  display block
  padding 20px 22px 22px 32px
  margin 20px 0 0 0
  background $white


  &--positive
    border-left 1px solid $jade

  &--negative
    border-left 1px solid $redRibbon

  &--standard
    border-left 4px solid $resolutionBlue

  p
  &__title
    margin 0 0 10px 0

  p
    font-size 14px
    line-height (20/14)
    color #666

  &__title
    p()
    display block
    color #000
