.logo
  display block
  background-image url('../images/logo.svg')
  background-repeat no-repeat
  width 207px
  height 64px

  +below('l')
    width 156px
    height 48px

  +below('m')
    width 130px
    height 40px


  &--small
    display block
    width 182px
    height 56px
    background-image url('../images/logo-small.svg')
    background-repeat no-repeat

    +below('l')
      width 156px
      height 48px

    +below('m')
      width 130px
      height 40px
