.form-simple
  display block
  background-color $white

  &__inner
    padding 24px 24px 32px

  &__group
    position relative
    margin 0 0 24px 0 !important
    padding-top 28px

  &__label-text
    position absolute
    top 0
    font-family $Muller
    font-size $font-size-p-muted
    line-height $line-height-p-muted
    color $silverChalice

  &__input:active + &__label-text
  &__input:focus + &__label-text
  &__textarea:active + &__label-text
  &__textarea:focus + &__label-text
    color $blueRibbon

  &__input.form__error + &__label-text
  &__textarea.form__error + &__label-text
    color $c_error

  &__input
  &__textarea
    margin 0 !important
    width 100%
    box-shadow inset 0 0 4px rgba(0, 0, 0, 0.16)


  &__input
    height 48px

  &__textarea
    height 128px
