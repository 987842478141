.payment-deposit
  display block
  width 388px
  padding 20px 24px 24px 32px
  background-color #F6F6F6

  +below('m')
    padding 20px 24px 24px 24px
    width 100%

  &__content
    inline-block(middle)
    padding 0 30px 0 0

    +below('m')
      margin 0 0 12px 0

  &__value
    margin 0
    font-family $Muller
    font-weight 400
    font-size 32px
    line-height (40/32)
    color #000

  &__text
    margin 0
    font-family $Muller
    font-weight 400
    font-size 16px
    line-height (22/16)
    color #A0A0A0

  &__btn-wrap
    inline-block(middle)

    +below('m')
      display block
