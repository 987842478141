hr
.hr
  display block
  width 100%
  height 1px
  border none
  background-color $silver

  &--24
    margin 24px 0

  &--32
    margin 32px 0
