.calc-form
  max-width 648px
  margin 0 auto
  box-sizing border-box
  padding 40px 20px 56px 20px
  background #FFF
  border 1px solid #0055FF
  box-shadow 0 0 16px 0 #CCCCCC
  border-radius 8px

  +below('m')
    border none
    padding 40px 0 56px
    transform none
    opacity 1
    border-bottom 1px solid transparent

  &--small
    max-width 304px
    margin 0
    padding 0
    border none
    box-shadow none

    .calc-form__range
      margin 0 0 24px

    .calc-form__wrapper
      margin 0

    +below('m')
      max-width 304px

  /*==============================
  =            Wrapper           =
  ==============================*/

  &__wrapper
    width 336px
    margin 0 auto
    position relative

    +below('m')
      max-width 240px
      width 100%

    &--small
      width 240px

      +below('m')
        width 240px

  &__wrapper-minutes
    min-height 94px
    padding 0 0 2px 0
    margin 0 0 22px 0
    white-space nowrap

    +below('m')
      min-height 56px
      height 69px

  &__field-wrapper
    margin 0 0 20px

  /*==============================
  =            Minutes           =
  ==============================*/

  &__minutes
    background none
    border none
    border-bottom 1px solid #A0A0A0
    width 180px
    font-size 64px
    margin-right 25px
    border-radius 0
    padding 1px
    color #000

    +below('m')
      width 125px
      margin-right 12px
      font-size 48px

  &__minutesfocus
    border-bottom 1px solid rgba(255, 255, 255, 1)
    outline none

  &__minute-label
    display inline-block
    vertical-align top
    padding-top 10px
    font-size 20px
    line-height 20px
    text-align left

    +below('m')
      font-size 14px
      line-height 20px

  &__minute-label
    font-family $Muller
    font-size 16px

    span
      font-family $Muller
      font-size 16px

  /*-----  End of Minutes  ------*/

  /*==============================
  =            Text             =
  ==============================*/

  &__text
    margin 0
    text-align left
    font-family $Muller
    font-weight 400
    font-size 16px
    line-height (22/16)
    color #000

  &__field-text
    display block
    margin 4px 0 0 0
    text-align left
    font-family $Muller
    font-weight 400
    font-size 12px
    line-height (16/12)
    color $silverChalice

  /*-----  End of Text   ------*/


  &__range
    margin 0 -38px 24px -38px
    padding 11px 0 5px
    position relative

    +below('m')
      margin 0 0 24px 0

  &__range input
    width 100%
    padding 0

  &__input
    width 100%
    box-sizing border-box
    background none
    border none
    border-bottom 1px solid #A0A0A0
    font-family $Muller
    font-size 64px
    border-radius 0
    padding 1px
    color #000

    +below('m')
      font-size 48px

  &__value
    inline-block(top)
    margin 0 0 0 8px
    font-family $Muller
    font-weight 500
    font-size 16px
    color $tangerine
    line-height (22/16)

  /*==============================
  =            Price             =
  ==============================*/

  &__price
    padding 0
    margin 0
    font-size 0
    text-align left

  &__price-title
    font-family $Muller
    font-size 20px
    width 212px
    display inline-block
    line-height 26px
    margin-bottom 2px

    +below('m')
      font-size 14px
      width 140px

    &--small
      font-size 16px
      line-height 22px

  &__price-value
    font-family $Muller
    font-size 24px
    line-height 32px
    font-weight 500
    display inline-block
    margin 0

    +below('m')
      font-size 20px

  &__price-value--discount
    font-size 16px
    line-height 22px
    color #F39200

  /*-----  End of Price   ------*/

  /*==============================
  =            Submit            =
  ==============================*/

  &__submit
    margin 0 auto
    position relative
    top 38px
    display block
    outline none
    font-family 'Muller', Helvetica, Arial, sans-serif
    background-color #fff
    padding-left 32px
    padding-right 32px
    font-size 20px
    color #0055FF
    line-height 36px

  &__submit:hover
    color #fff
    background #F39200
    border-color #F39200

  /*-----  End of Submit  ------*/

input::-webkit-outer-spin-button
input::-webkit-inner-spin-button
  -webkit-appearance: none;
  margin: 0;
//
//input[type=number] {
//  -moz-appearance:textfield;
//}
//
//input[type="number"]:hover,
//input[type="number"]:focus {
//  -moz-appearance: number-input;
//}
