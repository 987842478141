.inline-switch
  @extend .cf
  display block
  padding 8px 16px 8px 20px
  border 1px solid #f9f9f9
  background-color #eee
  transition $time ease

  &:hover
    background-color #eee
    border 1px solid #ccc

  &__content
    padding 0 116px 0 0

  &__switch-wrap
    float right

  &__text
    p()
    color $black
