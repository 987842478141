.product-option
  display block
  margin 0 auto
  padding 16px 0 0 0
  text-align center

  +below('m')
    padding 0

  &__wrapper
    max-width 576px
    margin 0 auto 40px auto

    +below('m')
      max-width 476px
      margin 0 auto 40px auto

  &__slide-next
    max-width 480px
    margin 0 auto
    background-color #EEE
    padding 24px 0 16px 0
    border-radius 4px 4px 0 0

    +below('m')
      max-width 240px
      padding 24px 25px 16px 25px

  &__slide-next--top
    padding 24px 0 16px 0

  &__slide-next--bottom
    padding 16px 0 24px 0
    border-radius 0 0 4px 4px

    +below('m')
      max-width 240px
      padding 12px 25px 20px 25px

  &__slide

    .calc-form
      display none

    .product-option__slide-next
      display none

  &__slide--show
    display block

    .calc-form
      display block

    .product-option__slide-next
      display block

  &__text-small
    font-family $Muller
    font-size 14px
    color $silverChalice
    line-height 20px

  &__link
    font-family $Muller
    font-size 16px
    line-height 20px
    color $blueRibbon
    cursor pointer
