@font-face {
	font-family: 'Agipo';
	font-style: normal;
	font-weight: bold;
	src: local('Agipo_Bold'), url(../fonts/Agipo_Bold.woff2) format('woff2'), url(../fonts/Agipo_Bold.woff) format('woff');
}

@font-face {
	font-family: 'Agipo';
	font-style: normal;
	font-weight: normal;
	src: local('Agipo_regular'), url(../fonts/Agipo_regular.woff2) format('woff2'), url(../fonts/Agipo_regular.woff) format('woff');
}


@font-face {
	font-family: 'Muller';
	font-style: normal;
	font-weight: 400;
	src: local('Muller-Regular'), url('../fonts/Muller-Regular.woff2') format('woff2'), url('../fonts/Muller-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Muller';
	font-style: normal;
	font-weight: 500;
	src: local('Muller-Medium'), url('../fonts/Muller-Medium.woff2') format('woff2'), url('../fonts/Muller-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Muller-Bold';
	font-style: normal;
	font-weight: 700;
	src: local('Muller-Bold'), url('../fonts/Muller-Bold.woff2') format('woff2'), url('../fonts/Muller-Bold.woff') format('woff');
}


@font-face {
	font-family: 'ALSRubl-Regular';
	src: local('ALSRubl-Regular'), url('../fonts/ALSRubl-Regular.woff2') format('woff2'), url('../fonts/ALSRubl-Regular.woff') format('woff');
	font-style: normal;
	font-weight: 400;
}
