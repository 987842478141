.call-list
  display block

  &__title
    h3()
    margin 24px 0 16px 0

    +below(m)
      font-size $font-size-mobile-h3
      line-height $line-height-mobile-h3
