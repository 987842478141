.switch__input
  display none

.switch__input + .switch__label
  inline-block(middle)
  size(96px,40px)
  background-color $silverChalice
  background-image none
  border-radius 40px
  cursor pointer
  text-align left
  position relative
  font-family inherit
  -webkit-transition all 0.1s ease-in-out
  transition all 0.1s ease-in-out


.switch__input + .switch__label:before
  p()
  content attr(data-off-label)
  display block
  position absolute
  right 17px
  margin 0
  top 50%
  transform translate(0, -50%)
  overflow hidden
  color white
  transition all $time ease-in-out


.switch__input + .switch__label:after
  size(35px)
  content ''
  position absolute
  top 50%
  transform translate(0, -50%)
  left 3px
  background-color #fff
  transition all $time ease-in-out
  border-radius 50%
  //box-shadow 0px 1px 3px 0px rgba(0,0,0,0.50)
  box-shadow 0px 1px 3px 0px rgba(0,0,0,0.24), inset 0px 0px 4px 0px rgba(0,0,0,0.16)


.switch__input:checked + .switch__label
  background-color $blueRibbon


.switch__input:checked + .switch__label:before
  color #fff
  content attr(data-on-label)
  left 20px
  &::selection
    background-color transparent


.switch__input:checked + .switch__label:after
  left 58px
  background-color #fff

  &::selection
    background-color transparent
    
.switch__input + .switch__label:hover:after
  background-color #eee

