.call-history
  font-family $Muller

  &__time
  &__person
  &__target
    display block
    margin 0 0 8px

  &__person
  &__target
    font-size 14px
    line-height 1.43

  &__time
    font-size 16px
    line-height 1.25
    color $blackPearl

  &__person
    color $black

  &__target
    color $doveGray