.chart
  inline-block(top)
  size(128px)
  position relative
  margin 0 24px 0 0

  &:last-child
    margin 0

  &__canvas
    position absolute
    top 0
    left 0
    display block

  &__value
    position absolute
    top 50%
    left 50%
    display block
    width 128px
    text-align center
    font-family $Muller
    font-weight 400
    font-size 20px
    line-height (24/20)
    transform translate(-50%, -50%)
    color #00

  &__units
    display block
    line-height 24px
