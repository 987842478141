.radio-group
  margin 0 0 8px 0

  &--selected
    margin 0

    .radio
      display block
      background-color $abalaster
      padding 10px 0 10px 20px

      &:hover
        background-color $gallery

    .radio__icon
      top 50%
      left 20px
      transform translate(0,-50%)
