.box
  margin 48px 0 48px 0
  border-bottom 1px solid $borderColor
  padding 0 0 48px

  &--info
    margin 0 0 64px 0
    padding 32px 32px 30px 32px
    background-color #fff
    border none
    text-align left

    +below('m')
      padding 32px 0 30px 16px
      margin 0 0 16px 0

    .box__title
      h3()
      margin 0 0 16px 0

      &--small
        h4()
        margin 0 0 12px 0

    .box__text-muted
      margin 0 0 0 6px
      white-space nowrap

      +below('l')
        display block
        margin 8px 0 12px 0


      +below('m')
        display block
        margin 8px 0 12px 0

  &--info-onboarding
    padding 0 32px
    margin 0 0 40px 0

    //+below('l')
    //  padding 0 94px

    +below('m')
      padding 0

  &--time
    padding 16px 32px
    margin 0 0 16px 0
    background-color #fff
    border-bottom 0

  &__text
    p-muted()
    display block
    margin 0

  &__title
    h2()
    display block
    margin 0
