.tooltip
  inline-block(middle)
  size(24px)
  position relative
  z-index 10
  
  svg
    display block
    size(24px)
    
  +below('l')
    display none
  
  &:hover

    .tooltip__content
      opacity 1
      visibility visible
      transition-delay 100ms
      transform translateY(-10px)
      z-index 10
      
    .tooltip__triangle
      transition-delay 200ms
      opacity 1
      visibility visible

  &__content
    p()
    position absolute
    bottom 28px
    left -20px
    width 236px
    padding 16px 24px 15px 20px
    background #FFFFFF
    border 1px solid #EEEEEE
    box-shadow 0 0 8px 0 #DDDDDD
    text-align left
    color #000
    transform translate3d(0, 0, 0)
    transition 0.3s ease
    transition-delay 0
    opacity 0
    visibility hidden
    
    &:before
      content ''
      position absolute
      bottom -33px
      left 12px
      display block
      border 20px solid transparent
      border-top-color #fff

  &__triangle
    size(30px)
    position absolute
    left -2px
    bottom 34px
    background #fff
    transform rotate(-135deg)
    box-shadow -1px -1px 10px -2px rgba(0, 0, 0, 0.5)
    transition 0.15s ease
    transition-delay 0
    opacity 0
    visibility hidden

  &__text
    margin 0
