.menu-nav
  display block
  margin 48px 0 0 0
  
  &__title
    display block
    margin 0 0 8px 0
    font-family $Muller
    font-size 40px
    line-height $line-height-h1
  
  &__item
    margin 0 0 6px 0
    display block
