.box-stat
  display block
  margin 0 0 53px 0
  font-size 0

  &__now
    inline-block(top)
    border-right 1px solid $borderColor

  &__total
    inline-block(top)

  &__now-data
    inline-block(top)
    margin 0 58px 0 0

    & + &
      margin 0 38px 0 0

  &__total-data
    inline-block(top)
    margin 0 0 0 48px

  &__value
    display block
    margin 0
    font-family $Muller
    font-size $font-size-title-display
    line-height $line-height-title-display

  &__value-small
    display block
    margin 0
    font-family $Muller
    font-size $font-size-h2
    line-height $line-height-h2
    font-weight 500

  &__text
    display block
    margin 0
    font-family $Muller
    font-size $font-size-p-muted
    line-height $line-height-p-muted
    font-weight 400
    color $silverChalice




