.header
  display block
  padding 16px 0
  min-height 96px
  background $white

  +below('m')
    min-height 64px

  &--small
    padding 0
    min-height 0

    +below('l')
      min-height 108px

    +below('m')
      min-height 96px

    .header__top
      padding 8px 0 4px 0

  &__account
    @extend .cf
    float right
    display block
    padding 4px 0 0 0

    +below('l')
      display none

    &--user
      padding 7px 0 0 0

  &__account-pic-wrap
    float left
    padding 5px 0 0 0

  &__account-pic
    size(48px)
    display block
    border-radius 50%

  &__account-content
    padding 8px 0 0 66px

  &__account-name
    p()
    margin 0 0 4px 0

  &__account-link
    link()

  &__status
    @extend .cf
    inline-block(top)
    padding 10px 0 0 0

    +below('m')
      padding 12px 0 0 0

  &__contact
    inline-block(top)
    padding 20px 40px 0 0
    margin 0

    +below('l')
      padding 13px 0 0 0

    +below('m')
      padding 10px 0 0 0

  &__contact-text
    margin-top 0
    margin-bottom 0
    color #979797
    font-size 14px

    +below('m')
      display none

  &__contact-phone-number
    font-family $Muller
    font-size 16px
    line-height 24px
    color #000
    pointer-events none

    +below('m')
      link()
      pointer-events auto

  &__contact-link

    +below('m')
      display none

  &__help
    display none

    +below('m')
      padding 6px 0 0 6px
      inline-block(top)
    
  &__account-user
    display none
    
    +below('l')
      inline-block(top)
      padding 12px 0 0 8px

    +below('m')
      padding 8px 0 0 8px
