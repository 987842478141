.side-nav
  padding 0 0 0 48px

  &--fixed
    width 240px
    height 100vh
    position fixed
    left 0
    background-color #fff
    padding 20px 0 24px 0
    z-index 10

    .side-nav__logo-wrap
      padding 0 38px 15px 20px

    .side-nav__bottom
      padding 0 23px
      bottom 28px
      position absolute

    +below('l')
      display none

  &--no-padding
    padding 0

  &__item
    display block

    &.active

      .side-nav__item-content
        background-color #f6f6f6

      .side-nav__name
        color #050D25

      .side-nav__icon-wrap
        fill #041B50

        svg
          fill #041B50

      .side-nav__submenu
        display block

    &:hover

      .side-nav__item-content
        background-color #f6f6f6

  &__name
    inline-block(middle)
    font-family $Muller
    font-size 20px
    color $blueRibbon

    &--small
      font-size 16px

  &__icon-wrap
    inline-block(middle)
    margin 0 10px 0 0
    fill $blueRibbon

    &--right
      margin 0 0 0 16px

  &__item-content
    display block
    padding 11px 23px
    transition $time ease

    &--small
      padding 9px 23px

  &__submenu
    margin-top -10px
    display none
    padding 0 23px 12px 58px
    background-color #f6f6f6

  &__submenu-item
    font-family $Muller
    font-size 16px
    color #0055FF
    line-height (22/16)