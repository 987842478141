.client-preview
  @extend .cf
  display block
  margin 0 0 30px 0

  &__pic-wrap
    float left

  &__pic
    size(144px)

  &__content
    padding 0 0 0 191px // 144 + 47

  &__top
    margin 0 0 20px 0

  &__group
    inline-block(middle)

    & + &
      margin 0 0 0 24px

  &__name
    h2()
    margin 0
    color $black

  &__status
    p()
    position relative
    padding 0 0 0 18px
    color $black

    &:before
      content ''
      size(10px)
      position absolute
      top 50%
      left 0
      transform translate(0,-50%)
      display block
      border-radius 50%

    &--nok
      &:before
        background $redRibbon

    &--ok
      &:before
        background $jade

  &__text
    margin 0

