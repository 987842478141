$containerWidth = 1152

.ui-section
  margin 40px 0 0 0

  &--top
    margin 100px 0 0 0

  &--half-right
  &--half-left
    width ($containerWidth/2)px

  &--half-right
    padding 0 0 0 96px

  &--half-left
    margin-left auto
    padding 0 96px 0 0
