.footer

  &--onboarding
    width 960px
    min-height 194px
    margin 0 auto 48px auto
    padding 32px 0 48px 0
    text-align center
    background-color #EEEEEE

    +below('l')
      margin 0 auto
      width 100%

      +below('m')
        padding 32px 16px 48px 16px

    .footer__title
      margin 0 0 8px 0

    .footer__text
      margin 0 0 16px 0

    .footer__btn-wrap
      position relative

    .footer__btn

      +below('m')
        position relative
        display block
        margin 6px 0 0 0
