.operator
  width 100%
  height 100%
  font-family $Muller

  &__header
    margin 0 0 22px
    padding-left 23px

  &__title
    margin 0 0 8px
    font-family $Muller
    font-weight 500
    font-size $font-size-h4
    line-height $line-height-mobile-h3

  &__subtitle
    margin 0
    font-family $Muller
    font-size $font-size-p-muted
    line-height 20px

  &__nav
    position fixed
    top 0
    left 0
    padding-top 41px
    width 384px
    height 100%
    background #fff

  &__instructions
    margin-left 432px
    padding-top 17px
    padding-bottom 73px
    width 720px

  &__tools
    position fixed
    top 0
    left 1200px
    padding-top 17px
    width 384px
    height 100%

  &__btn
    position fixed
    top 0
    right 0
    width 96px
    height 100%
    border none
    background rgba(221,221,221,.6)

    span
      position absolute
      top 35px
      left 0
      width 100%
      text-align center
      font-size 14px
      color $silverChalice

      &:before
        content ''
        position absolute
        top -11px
        right 43px
        z-index 10
        border-left 6px solid transparent
        border-bottom 7px solid currentColor
        border-right 6px solid transparent

.operator-tools

  &__phone
  &__form
    margin-bottom 40px


  &__history
    height 230px
    padding 24px
    border 1px solid $alto
    overflow-y auto


.operator-tools-history
  padding-top 16px

  &__item
    margin-bottom 24px

    &:last-child
      margin-bottom 8px