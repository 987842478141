.toggle-control
  @extend .cf
  position relative
  display block
  padding 8px 132px 8px 20px
  border 1px solid #eee
  background-color #f9f9f9
  transition $time ease
  margin 0 0 24px 0

  +below('m')
    padding 0
    background-color transparent
    border-color transparent

  &:last-child
    margin 0

  &:hover
    background-color #eee
    border 1px solid #ccc

    +below('l')
      border 1px solid #eee
      background-color #f9f9f9

    +below('m')
      background-color transparent
      border 1px solid transparent

  &__switch-wrap
    inline-block(top)
    position absolute
    right 16px
    top 8px
    cursor pointer

    +below('m')
      position relative
      top 0
      left 0
      display block

  &__text
    p()
    table-cell(middle)
    height 40px
    color $black

    +below('m')
      display block
      margin 0 0 12px 0
      height initial
      min-height 20px
      line-height 20px
