.user-preview
  display block
  width 384px
  margin 0 0 16px 0
  padding 24px 57px 24px 32px
  background-color #fff

  &__pic-wrap
    float left

  &__pic
    size(64px)
    border-radius 50%

  &__content
    padding 0 0 0 88px

  &__name
    h4()
    margin 0 0 4px 0
