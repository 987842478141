textarea
input
  input-text()
  outline none
  border 1px solid $borderColor
  padding 11px 47px 10px 20px
  color $black

  &:hover
    border-color $silverChalice

  &:focus
    border-color $blueRibbon
    outline none

  &:active
    border-color $blueRibbon

  &::-webkit-input-placeholder
    color: $silverChalice!important
    font-size: 16px;
    line-height: 20/16;
  &::-moz-placeholder
    color: $silverChalice!important
    font-size: 16px;
    line-height: 20/16;
  &:-moz-placeholder
    color: $silverChalice!important
    font-size: 16px;
    line-height: 20/16;
  &:-ms-input-placeholder
    color: $silverChalice!important
    font-size: 16px;
    line-height: 20/16;

  &:focus::-webkit-input-placeholder
    color $silverChalice!important
  &:focus::-moz-placeholder
    color $silverChalice!important
  &:focus:-moz-placeholder
    color $silverChalice!important
  &:focus:-ms-input-placeholder
    color $silverChalice!important

textarea
input

  &.error
    border-color $c_error
    //animation shake .6s

textarea
.textarea
  width 100%

.textarea.textarea--drag
  resize vertical
  height 246px
