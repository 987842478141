.page
  min-height 100%

  +below('l')
    display flex
    flex-direction column

  +below('m')
    display block
    flex-direction none

  &--gallery
    background-color $gallery

    +below('l')
      background-color #fff
    
  &--blue
    background-color $blueRibbon

    +below('l')
      background-color #fff
