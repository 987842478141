/*===============================
=            HELPERS            =
===============================*/

.pull-left
  float left !important

.pull-right
  float right !important

.to-right
  margin-right 8px

.to-left
  margin-left 8px

.text-left
  text-align left

.text-center
  text-align center!important

.text-right
  text-align right

.block
  display block

.fz0
  font-size 0 !important

.mt0
  margin-top 0 !important

.mr0
  margin-right 0 !important

.mb0
  margin-bottom 0 !important

.ml0
  margin-left 0 !important

.pt0
  padding-top 0 !important

.pr0
  padding-right 0 !important

.pb0
  padding-bottom 0 !important

.pl0
  padding-left 0 !important

.nowrap
  white-space nowrap !important

.bg-white
  background $white !important

.bg-gallery
  background $gallery !important

.bg-black-pearl
  background $blackPearl !important

.c-gallery
  color $gallery !important

.c-black-pearl
  color $blackPearl !important

.c-white
  color $white !important
  
.c-blue
  color $blueRibbon!important

.c-nok
.red
  color $redRibbon!important

.c-ok
.green
  color $jade!important

.block
  display block!important

.hide
.hidden
  display none !important

.show
.shown
  display block !important

.tablet-hide

  +below(l)
    display none!important

.tablet-show

  +below(l)
    display block!important

.mobile-hide

  +below(m)
    display none!important

.mobile-show
.mobile-block

  +below(m)
    display block!important

  &--inline-block

    +below(m)
      display inline-block!important

.overflow
  overflow hidden


/*-----  End of HELPERS  ------*/
