.status
  display block
  margin 0
  padding 0 0 0 12px
  background $white

  span
  p
    font-size $font-size-p
    line-height $line-height-p
    font-weight 400
    margin 0
    color #666

  &--positive
    border-left 2px solid $jade

  &--negative
    border-left 2px solid $redRibbon
