.main-nav
  display block
  position relative
  height 48px
  background-color #000
  transition all .3s

  /*==============================
  =            STATE             =
  ==============================*/

  &--fixed

    +below('l')
      position fixed
      top 0
      left 0
      right 0
      z-index 10
      box-shadow 0 1px 0 0 rgba(204,204,204,0.50)
  
  &--small
    background #fff

    +below('m')
      height 44px

    .main-nav__item-name
      p()
      padding 13px 0
      color $blueRibbon

      +below('m')
        font-size 14px

    .main-nav__item:hover .main-nav__item-name-text
    .main-nav__item.active .main-nav__item-name-text
      position relative
      color #000

      &:after
        content ''
        position absolute
        right 0
        left 0
        bottom -16px
        height 4px
        background-color #000

        +below(m)
          bottom -15px

    .main-nav__item:hover
    .main-nav__item.active

        &:after
          content none

  /*-----  End of STATE   ------*/

  &__list
    list-style none
    padding 0
    margin 0
    font-size 0

  &__item
    inline-block(top)
    margin 0 32px 0 0
    transition $time ease all

    +below('m')
      margin 0 14px 0 0

      &:nth-last-child(2)
        margin 0

    &:last-child
      margin 0

    &:hover
    &.active
      position relative

      &:after
        content ''
        position absolute
        right 0
        left 0
        bottom 0
        height 4px
        background-color #fff


  &__item--help

    +below('m')
      display none

  &__item-name
    display block
    padding 12px 0
    font-family $Muller
    font-size 16px
    line-height 24px
    font-weight 400
    color #fff

  &__item-label
    margin 0 0 0 6px

    +below('m')
      margin 0 0 0 4px
