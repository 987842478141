.balance
  background-color $blueRibbon
  margin 0 0 24px 0

  &__item
    height 212px
    padding 36px 0 32px 0

    +below('xl')
      padding 36px 0 34px 0

    +below('m')
      height auto
      padding 24px 10px 16px 17px

    &--dashed
      padding 34px 0 32px 54px
      border-left 1px dotted rgba(255,255,255, .4)

      +below('xl')
        padding 34px 0 48px 32px

      +below('m')
        border-left none
        border-top 1px dotted rgba(255,255,255, .4)
        padding 20px 10px 24px 17px

  &__minutes
    inline-block(top)
    height 112px
    margin 0

    +below('l')
      height auto

    +below('m')
      margin 0 0 5px

  &__minutes--empty
    height auto
    margin 0

    +below('m')
      margin 0

  &__minutes-value
    inline-block(baseline)
    margin 0 12px 0 0
    font-family $Muller
    font-weight 400
    font-size 112px
    line-height inherit
    color #fff

    +below('xl')
      font-size 96px

    +below('l')
      font-size 64px
      line-height 80px

  &__value
    inline-block(top)
    white-space nowrap
    margin 0 0 18px 0
    font-family $Muller
    font-weight 500
    font-size 64px
    line-height 72px
    height 72px
    color #fff

    +below('l')
      display block
      height auto

    +below('xl')
      font-size 48px
      margin 0 0 5px 0

    +below('l')
      font-size 32px
      line-height 40px
      margin 0 0 14px 0

    +below('m')
      line-height 40px
      margin 0 0 14px 0

  &__minutes-title
    display block
    margin 0 0 8px 0
    font-family $Muller
    font-size 20px
    line-height (28/20)
    color #fff

  &__minutes-text
    inline-block(baseline)
    p()
    color #fff

  &__minutes-text-small
    display block
    font-family $Muller
    font-size 12px
    line-height (16/12)
    color #fff

  &__minutes-text-additional
    p()
    margin 0 0 32px 0
    color #fff

    +below('xl')
      margin 0 0 28px 0

    +below('l')
      margin 0 0 16px

    +below('m')
      margin 0 0 28px 0

  &__title
    p()
    margin 0 0 6px 0
    color #fff

    +below('m')
      margin 0

  &__text
    p()
    margin 15px 0 6px 0
    color #fff

    +below('m')
      margin 5px 0 6px 0
      font-size 14px

  &__text--complex
    margin 4px 0 6px 0

  &__text-name
    font-size 14px
    line-height 16px

  &__text-name-small
    display block
    font-size 12px
    line-height (16/12)

  &__text-name-wrap
    inline-block(middle)
    margin-right 14px

    +below('l')
      padding 0
      margin 0 0 8px 0
      display block
    
  &__link
    color #fff

  & &__btn
    vertical-align middle

    +below('m')
      vertical-align middle
      height 32px!important
      font-size 14px!important
      line-height 32px!important
