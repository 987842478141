.label
  inline-block(middle)
  border-width 1px
  border-style solid

  text-align center
  text-decoration none

  font-family $Muller
  font-weight 400

  white-space nowrap

  transition all $time

  user-select none
  outline none

  &--standard
    padding 0 32px
    height 40px
    font-size 16px
    line-height 40px
    background $resolutionBlue
    color $white

    +below('m')
      padding 0 16px

  &--positive
    padding 0 32px
    height 40px
    font-size 16px
    line-height 40px
    background-color $brightSun
    color $black
    border none

    +below('m')
      padding 0 16px


  &--small
    inline-block(middle)
    padding 0 6px
    height 16px
    border-color $brightSun
    background-color $brightSun
    border-radius 100px
    font-family $Muller
    font-weight 400
    font-size 12px
    line-height (16px - 2)
    color #000000

    & + &
      margin 0 0 0 8px
