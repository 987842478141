.table-balance

  &__body-row
    font-size 0
    margin 24px 0 0 0

    &:first-child
      margin 0

    +below('m')
      margin 16px 0 0 0

  & &__body-item
    inline-block(top)
    width 87px
    padding 0
    margin 0 16px 0 0

  & &__body-item:last-child
    width 295px
    margin 0

  &__body-name
    display inline
    font 300 16px/22px $Muller
    color #000

  &__body-name--date
    color #A0A0A0

  &__body-name--ok
    color $c_ok

  &__body-name--nok
    color $c_error

  &__comment
    display inline
    font 300 16px/22px $Muller

    &--negative
      color $c_error

  &__body-row--hide
    display none

  &__show-more
    inline-block(top)
    margin 20px 0 0 0
    cursor pointer
    transition $time ease

    &:hover

      .table-balance__show-more-text
        border-bottom 1px solid $blueRibbon

    &--backwards

        .icon
          transform rotate(-180deg)

  &__show-more-text
    vertical-align top
    padding 0 0 2px 0
    font 300 14px/31px $Muller
    color $blueRibbon
    transition $time ease
    border-bottom 1px solid transparent

