.form
  display block

  &--admin
    margin 32px 0 0 0

  &__error
    display block
    margin 3px 0 0 0
    border-color $c_error!important
    font-family $Muller
    font-weight 400
    font-size 14px
    line-height (20/14)
    color $c_error
    text-align left

  &__group
    width 100%
    margin 0 0 20px 0

    &--complex
      margin 0 0 40px 0

    &--hide
      display none

  &__info
    margin 12px 0 0 0

  &__info-link
    display block

  &__name
  &__field
    inline-block(middle)
    width 352px

  &__name
    padding 0 0 0 24px

  &__title
    h3()
    display block
    margin 24px 0

    &:first-child
      margin-top 0

  &__text
    p()
    margin 5px 0 0 0

  &__text--medium
    font-size 14px
    line-height (20/14)
    color $c_muted

  &__text--small
    font-size $font-size-p-small
    line-height $line-height-p
    color $c_muted

  &__text--right
    margin 0 8px 0 0

  /*==============================
  =            INPUT             =
  ==============================*/

  &__input-wrap.form__error
  &__input.form__error
    margin 0

  &__input-wrap
    inline-block(baseline)
    width 100%
    position relative
    text-align left

  &__label
    position absolute
    top 16px
    left 17px
    width auto
    padding 0 5px
    color #A0A0A0
    font-family $Muller
    font-size 12px
    line-height (16/12)
    z-index 2
    pointer-events none
    background #fff
    transition transform $time ease
    transform translateY(-22px)

  &__label:before
    left 17px
    content attr(data-first)
    white-space nowrap

  &__input
    position relative
    width 100%
    height 48px
    color #000
    outline none

  &__input:invalid + label
    transform translateY(0)
    font-size 16px
    line-height (20/16)

  &__input:focus + .form__label
    left 17px
    transform translateY(-22px)
    font-size 12px
    line-height (16/12)
    padding 0 5px

  &__input:focus + .form__label:before
    content attr(data-second)
    border-color $blueRibbon
    color $blueRibbon
    font-size 12px
    line-height (16/12)


  &__input:required:invalid + .form__label[data-first][data-second]:before
    content attr(data-first)


  &__input:required:focus + .form__label[data-first][data-second]:before
    content attr(data-second)


  &__input:required + .form__label[data-second]:before
    content attr(data-second)

  &__input-info
    display block
    margin 4px 0 0 0
    text-align left

  /*-----  End of INPUT  ------*/

  &__icon-wrap
    width 46px
    height 46px
    position absolute
    top 1px
    right 1px
    cursor pointer
    user-select none
    outline none
    background-color transparent
    border none
    transition fill .3s ease

    &:hover
      fill $blueRibbon

    svg
      height 100%!important

  &__footer
    @extend .cf
    margin 49px 0 0 0
    padding 16px 0
    border-top 1px solid $borderColor
    border-bottom 1px solid $borderColor

  &__footer--small
    margin 32px 0 0 0
    padding 0
    border none

  /*==============================
  =            CALENDAR          =
  ==============================*/

  &__input-calendar
    padding 14px 30px 14px 49px
    position relative
    border 1px solid transparent
    border-radius 100px

    &::-webkit-input-placeholder
      font-family $Muller
      color $blueRibbon!important
    &::-moz-placeholder
      font-family $Muller
      color $blueRibbon!important
    &:-moz-placeholder
      font-family $Muller
      color $blueRibbon!important
    &:-ms-input-placeholder
      font-family $Muller
      color $blueRibbon!important

    &:hover
      border-color transparent

    &:focus
      border-color transparent

    &:active
      border-color transparent

    &:focus::-webkit-input-placeholder
      color $blueRibbon!important
    &:focus::-moz-placeholder
      color $blueRibbon!important
    &:focus:-moz-placeholder
      color $blueRibbon!important
    &:focus:-ms-input-placeholder
      color $blueRibbon!important

  &__datepicker-control
    width 46px
    height 46px
    position absolute
    top 1px
    left 5px
    cursor pointer
    user-select none
    outline none
    background-color transparent
    border none
    z-index 1

    svg
      margin 4px 0 0 0

  &__datepicker-control--close
    size(22px)
    display none
    top 13px
    left inherit
    right 16px
    border 1px solid $blueRibbon
    border-radius 50%

    svg
      position absolute
      top 50%
      left 50%
      transform translate(-50%,-50%)
      margin 0

  /*-----  End of CALENDAR  ------*/


